import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useUserAction } from "./Factory";

function Logout () 
{
    const userAction = useUserAction();
    const history = useHistory();
    useEffect( () =>
    {
        userAction.handleLogOut();
        history.push( "/affiliate" );
    }, [ history, userAction ] );

    return <></>;
}

export default Logout;
