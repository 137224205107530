import
{
  bitcoin,
  nation,
  pulse,
  techpoint,
  nairametrics,
  vanguardng,
  guardian,
  tc
} from "../../components";

export const sendSteps = [
  {
    text: "Click on “Send Money” ",
    icon: "arrow",
  },
  {
    text: "Select and input account or phone number",
    icon: "pointer",
  },
  {
    text: "Input amount and select crypto to send",
    icon: "alpha",
  },
  {
    text: "Verify your email address for  notifications",
    icon: "notification",
  },
  {
    text: "Send crypto and recipient will  be credited",
    icon: "share",
  },
];

export const receiveSteps = [
  {
    text: "Click on ‘Receive Money’ ",
    icon: "arrow",
  },
  {
    text: "Select and input your account  or phone number",
    icon: "pointer",
  },
  {
    text: "Verify your email address for notifications",
    icon: "notification",
  },
  {
    text: "Copy and share your unique link to receive money",
    icon: "copy",
  },
];

export const testimonials = [
  {
    text:
      "Easyshare is a breath of fresh air. I particularly love its clean and intuitive interface. It has also saved me a lot of time. I’ve had an amazing experience so far. Easyshare comes highly recommended.",
    name: "Tomi Olowojaiye",
    color: "#6dc4f2",
    img: "/tomi.jpg",
  },
  {
    text:
      "Easyshare has a great sales/customer experience and support. I was able to speak to a physical person who helped me with every step of my transaction. Smooth ride. I’ll definitely be using Easyshare again.",
    name: "Nwagbo Onuoha",
    color: "#ffc300",
    img: "https://media-exp1.licdn.com/dms/image/C5603AQG-9YnV-2cVOQ/profile-displayphoto-shrink_200_200/0/1516774329314?e=1631145600&v=beta&t=k_ZUEFs9Xw0WMp0PkHkWy-uTvwMhA2kYYo1tIlw90NU",
  },
  {
    text:
      "A friend recommended Easyshare to me and since then I’ve been hooked. If you’re looking for security, speed and the best rates all in one, then Easyshare is the place. No hidden fees or charges.",
    name: "Prince Ugomike Enwerem",
    color: "#33d5ba",
    img: "https://sunnewsonline.com/wp-content/uploads/2019/06/thumbnail-1-2-400x375.jpg",
  },
];

export const pressList = [
  {
    img: tc,
    alt: "Tech Cabal press logo",
    url: "https://techcabal.com/2021/06/08/easyshare-africa-launches-platform-using-safe-low-cost-cryptocurrency-technology/",
  },
  {
    img: techpoint,
    alt: "Techpoint press Logo",
    url: "https://techpoint.africa/2021/06/09/easyshare-africa-launches-platform-using-safe-low-cost-cryptocurrency-technology/",
  },
  {
    img: nairametrics,
    alt: "Nairametrics Press Logo",
    url: "https://nairametrics.com/2021/06/08/easyshare-africa-launches-platform-using-safe-low-cost-cryptocurrency-technology-helping-african-consumers-send-and-receive-as-little-as-1-using-crypto-from-anywhere-in-the-world/",
  },
  {
    img: bitcoin,
    alt: "BitcoinKE Press Logo",
    url: "https://bitcoinke.io/2021/06/introducing-easyshare/",
  },
  {
    img: pulse,
    alt: "Pulse Press Logo",
    url: "https://www.pulse.ng/business/easyshare-africa-launches-platform-using-safe-low-cost-cryptocurrency-technology/xkeqjjy",
  },
  {
    img: vanguardng,
    alt: "VanguardNg Press Logo",
    url: "https://www.vanguardngr.com/2021/06/easyshare-africa-appoints-prince-ugo-mike-enwerem-as-executive-director/",
  },
  {
    img: guardian,
    alt: "The Guardian Press Logo",
    url: "http://www.guardian.ng/news/mike-enwerem-emerges-easyshare-africas-executive-director/",
  },
  {
    img: nation,
    alt: "https://thenationonlineng.net/easyshare-africa-launches-platform/",
    url: "http://www.guardian.ng/news/mike-enwerem-emerges-easyshare-africas-executive-director/",
  },
];
