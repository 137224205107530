import React from 'react'
import { useUserStateAndAction } from "../../Factory";
import Gravatar from './Gravatar';

function GravatarContainer(props) {
     const {
       state: { user },
       action
     } = useUserStateAndAction();

     function handleOnChange(file){
         action.updateProfileImage(file).then( newProfile => {

         })
     }

    return (
      <Gravatar name={ user?.name } onChange={handleOnChange} />
    )
}

GravatarContainer.propTypes = {};

export default GravatarContainer;

