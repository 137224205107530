import React, { useEffect, useState } from "react";
import { usePage } from "../../hooks";
import {
  MultiStep,
  ContactDetails,
  WalletDetails,
  Calculator,
  SelectCrypto,
  ChooseRecieveMode,
} from "../../components";

import "./Share.scss";
import queryString from "query-string";
import {
  useTransactionState,
  useUiState,
  useTransactionAction,
} from "../../_helpers";

function Send() {
  const tState = useTransactionState();
  const Taction = useTransactionAction();
  const { UiState, UiActions } = useUiState();
  const [order, setOrder] = useState(null);
  const qParam= queryString.parse(window.location.search);
  const fromReq = qParam.frmReq;
 
  const sendSteps = ["bank", "calc", "contact", "wallet"];
  const fromReqSteps = ["contact", "calc", "wallet"];
  const steps = fromReq ? fromReqSteps : sendSteps;
  const { page, changePage, verifiedPages, clearVp } = usePage(steps);

  useEffect(() => {
    if (!tState.transactionType || tState.transactionType !== "SHARE") {
      Taction.setTransactionType("SHARE", fromReq);
      if (qParam?.affiliateId) {
        Taction.setAffiliateId(qParam?.affiliateId);
      }
      if (qParam?.userId) {
        Taction.setUserId(qParam?.userId);
      }
    }
  }, [
    Taction,
    tState.transactionType,
    fromReq,
    qParam?.affiliateId,
    qParam?.userId,
  ]);

  const globalProps = {
    page,
    changePage,
    data: { transactionalState: tState, core: UiState, order },
  };

  const text = {
    operator: "Please enter your recipient’s OPay phone number",
    bank: "Please enter your recipient’s account details",
  };

  const modeText = {
    primary: "I would like to send money to",
    main: `First, let’s get started with your <br /> preferred sending channel`,
  };

  const normalPages = [
    <ChooseRecieveMode modeText={modeText} text={text} {...globalProps} />,
    <SelectCrypto
      {...globalProps}
      setFormData={Taction.setAmountInfo}
      setTokenDto={Taction.setTokenDto}
      setPickedAsset = { UiActions.setPickedAsset }
    />,
    <Calculator
      {...globalProps}
      setFormData={Taction.setAmountInfo}
      setCurrencyOption={UiActions.setCurrency}
    />,
    <ContactDetails
      {...globalProps}
      setFormData={Taction.setCustomerDetails}
      setOrder={setOrder}
    />,
    <WalletDetails {...globalProps} />,
  ];
  const fromReqPages = [
    <SelectCrypto
      {...globalProps}
      setFormData={Taction.setAmountInfo}
      setTokenDto={Taction.setTokenDto}
      setPickedAsset={UiActions.setPickedAsset}
    />,
    <Calculator
      {...globalProps}
      setFormData={Taction.setAmountInfo}
      setTokenDto={Taction.setTokenDto}
      setCurrencyOption={UiActions.setCurrency}
      isUpdate
    />,
    <ContactDetails
      {...globalProps}
      setFormData={Taction.setCustomerDetails}
      setOrder={setOrder}
    />,
    <WalletDetails {...globalProps} />,
  ];

 let pages = fromReq && tState?.accountDetails?.accountName ? fromReqPages : normalPages;

  useEffect(() => {
    if (page === pages.length - 1) {
      clearVp();
    }
  }, [page, pages.length, clearVp]);

  return (
    <div className='Share'>
      <MultiStep
        pages={pages}
        page={page}
        changePage={changePage}
        verifiedPages={verifiedPages}
        title='Send Money'
      />
    </div>
  );
}

export default Send;
