import React, { useState } from "react";
//import Pagination from "./Pagination";
import Pagination2 from "./Pagination2";
import userFallBackImg from "../../../../assets/images/user.svg";
import "./Table.scss";

const getPageDisplay = ( data, postsPerPage, currentPage ) =>
{
  let indexOfFirstPost, indexOfLastPost;

  if ( data.length < postsPerPage )
  {
    indexOfFirstPost = 0;
    indexOfLastPost = data.length;
  }
  else
  {
    indexOfLastPost = currentPage * postsPerPage;
    indexOfFirstPost = indexOfLastPost - postsPerPage;
  }

  return { indexOfFirstPost, indexOfLastPost };

};

function Table ( { data, columns, totalPages, title, getPage, currentPage } )
{

  const [ postsPerPage ] = useState( 25 );

  // Get current rows to display
  const { indexOfFirstPost, indexOfLastPost } = getPageDisplay( data, postsPerPage, currentPage );
  const currentPosts = data?.slice( indexOfFirstPost, indexOfLastPost );

  // Change page
  // const paginate = ( pageNumber ) =>
  // {
  //   setCurrentPage( pageNumber );
  // };

  return (
    <div className='table table-responsive'>
      <div className='table_title'>
        <h3>{ title }:</h3>
        <h6>{ indexOfFirstPost } - { indexOfLastPost } / { data.length }</h6>
      </div>
      {
        data.length ? (
          <table >

            <thead>
              <tr>
                { columns &&
                  columns.map( ( { label }, index ) => (
                    <th key={ `${ label }${ index }` }>{ label }</th>
                  ) ) }
              </tr>
            </thead>

            <TableBody data={ currentPosts } columns={ columns } />

          </table>
        ) : <h3 className="empty">No data available</h3>
      }

      {/* 
      <Pagination postsPerPage={ postsPerPage } totalPosts={ data?.length } paginate={ paginate } currentPage={ currentPage } showMore={ setPostPerPage } /> */}

      <Pagination2
        totalPages={ totalPages }
        currentPage={ currentPage }
        getPage={ getPage }
      />
    </div>
  );
}
export default Table;

function TableBody ( { data, columns } )
{
  return (
    <tbody>
      { data &&
        data.map( ( row, index ) => (
          <tr key={ `table${ index }` }>
            { columns.map( ( { name, isImg, style = {}, formatter }, i ) =>
            (
              <td key={ `${ row[ name ] }${ i } data` } style={ style }>
                { isImg ? (
                  <img src={ row[ name ] || userFallBackImg } alt={ `${ name }` } />
                ) : (
                  formatter ? formatter( row[ name ] ) : row[ name ]
                ) }
              </td>
            )
            ) }
          </tr>
        ) ) }
    </tbody>
  );
}


// const formatOptions = {
//    isDate:"isDate",
//    isMoney:"isMoney"
// }

// function formatValue(value,option){
//    switch(option){
//      case formatOptions.isDate:
//        return new Date(value).toLocaleDateString();
//      case formatOptions.isMoney:
//        return format(value);
//       default:
//         return value
//    }

// }
