import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { format, makeReq } from "../../utils";
import { useTransactionAction } from "../../_helpers";
import "./HomeHero.scss";

function WithName({ rId }) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError ] = React.useState("");
  const tAction = useTransactionAction();
  const [userDetails, setUserDetails] = React.useState(null);
 
  const fetchUserReq = useCallback(() => {
    if (rId) {
      setError("");
      setLoading(true);
      makeReq(`send/?requestId=${rId}`)
        .then((cart) => {
          setUserDetails(cart.accountDetails);
          tAction.setAccountDetails(cart.accountDetails);
          tAction.setLinkRequestId(rId);
          setLoading(false);
        })
        .catch( err => {
          setError("failed to fetch request details")
        });
    }
  }, [rId, tAction]);

  useEffect(() => {
    fetchUserReq();
  }, [fetchUserReq]);

  

  if(error){
return (
  <div className='content with_error'>
    <p style={{ color: "red", fontSize: 24 }}>
      <i className='fa fa-exclamation-triangle'></i>{" "}
      {error}
    </p>
    <div className='cta-row'>
      <button className='btn-green' onClick={fetchUserReq}>
        resend
      </button>
      <Link className='btn-green' to="/">
        home
      </Link>
    </div>
  </div>
);
  }

  return loading ? (
    <p style={{ textAlign: "center" }}>
      <i className='fa fa-spinner fa-spin'></i>fetching user details...
    </p>
  ) : (
    <div className='content with_name'>
      <p className='light'>
        You have a SEND MONEY request
        {userDetails?.estimatedReceiveAmount && (
          <b>
            for NGN {format(userDetails?.estimatedReceiveAmount || 0)}{" "}
          </b>
        )}
        { " "}
        from
        <b className='user'> {userDetails?.accountName} </b>
      </p>

      <div className='cta'>
        <Link className='btn-green' to='/send?frmReq=true'>
          Proceed to send money
        </Link>
      </div>
    </div>
  );
}

export default WithName;
