import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./AddBankDetails.scss";

const AddBankDetails = ({ match, onCancelClick, showCancelClick, inModal }) => {
  return (
    <div className={`bank_content_alert ${inModal ? "inModal" : ""}`}>
      <div className='head'>
        <h2>Add Your Bank Account Details</h2>
        {showCancelClick && (
          <i
            className='fa fa-times'
            aria-hidden='true'
            onClick={onCancelClick}></i>
        )}
      </div>
      <div className='bod'>
        <p>
          To get started with Easyshare Affiliate program, you need to update
          your bank details
        </p>
        <Link
          to={{
            pathname: `${match.path}/settings`,
            state: {
              fromDashboard: true,
            },
          }}
          className='add'
          onClick={onCancelClick}>
          Add
        </Link>
      </div>
    </div>
  );
};

export const AddBankDetailsModal = ({ children, isMounted, match }) => {
  const [shown, setShown] = useState(true);

  const unMounted = useRef(false);

  useEffect(() => {
    if (!shown) {
      unMounted.current = true;
      isMounted(unMounted.current);
    }
  }, [shown, isMounted]);

  const onClick = () => {
    setShown(() => false);
  };

  return (
    <div className={`add_bank ${!shown ? "hide" : ""}`}>
      <AddBankDetails showCancelClick onCancelClick={onClick} match={match} inModal />
    </div>
  );
};

export default AddBankDetails;
