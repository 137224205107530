import React, {  useEffect, useState } from "react";
import "../Form.scss";
import DPD from "./DropDown";
import { BackBtn, Spinner } from "../../index";
import {  CurrencyProvider } from "../../../_helpers";
import { useCalculator, useUnload } from "../../../hooks";
import { withProvider } from "../../../hocs";

import CurrencyInput from "react-currency-input-field";
import { ReactComponent as Info } from "../../../assets/images/info.svg";
import ReactTooltip from "react-tooltip";
import queryString from "query-string";
import { checkedJsonStorage, format, isEmpty, NetworkMap } from "../../../utils";

function Calculator({
  changePage,
  page,
  setFormData,
  setCurrencyOption,
  data,
}) {
 
  const { receiveCurrency, sendCurrency, pickedAsset } = data.core;
  const { receiveEstimatedAmount, sendAmount, accountDetails, tokenDto } =
    data.transactionalState;

  

  const [defaultValue, setDefaultValue] = useState({
    receiveEstimatedAmount,
    sendAmount,
  });

  useEffect(() => {
    checkForHomeCalcEntries(({ currencies, values }) => {
      setCurrencyOption(currencies);
      setDefaultValue(values);
    });
    //eslint-disable-next-line
  }, []);

  
  const {
    send,
    receive,
    isValid,
    errors,
    touched,
    currencies,
    refs,
    isLoaded,
    setSend,
    setReceive,
  } = useCalculator({
    country: accountDetails.country || "NGN",
    networkOption:tokenDto?.network || NetworkMap[pickedAsset?.asset?.crypto],
    minAmount: pickedAsset?.asset?.minAmountUSD || tokenDto?.minAmountUSD || 0,
    maxAmount: process.env.REACT_APP_MAX_AMOUNT || Infinity,
    disableMinMaxValidation: !!+process.env.REACT_APP_DISABLE_MIN_MAX,
    defaultValue: {
      receive: defaultValue.receiveEstimatedAmount,
      send: defaultValue.sendAmount,
    },
    defaultPairs: {
      send: sendCurrency,
      receive: receiveCurrency,
    },
  });

  
  const tooltip = React.useRef();

  React.useEffect(() => {
    if (errors.limitError.isHigh) {
      ReactTooltip.show(tooltip.current);
    }
  }, [tooltip, errors.limitError.isHigh]);

  useUnload((e) => {
    e.preventDefault();
    const message = "your changes might be lost";
    e.returnValue = message;
    return message;
  });

  const goNext = (e) => {
    e.stopPropagation();
    changePage(page + 1, true);
  };

  const goBack = (e) => {
    e.stopPropagation();
    changePage(page - 1, true);
  };

  function handleSubmit(e) {
    if (isValid()) {
      setFormData({
        ...refs.getValues(),
      });
      //update core state
      setCurrencyOption({ receiveCurrency: receive, sendCurrency: send });
      goNext(e);
    }
  }

  

  

  const firstname = accountDetails?.accountName?.split(" ")[0];
  return (
    <div className='calculator'>
      <BackBtn to={goBack} />
      <p className='formMessage'>
        to <span className='emph bold'>{firstname}</span>
        <br />
        {accountDetails.bankName}
      </p>
      <div style={{ margin: "20px 0" }}></div>

      <div className='form-row'>
        <label htmlFor='from'>
          How much do you want to send{" "}
          {errors.send && touched && <small>({errors.send})</small>}
          {errors.limitError.isHigh && (
            <span
              ref={tooltip}
              className='info'
              data-tip={`please chat with us to send <br /> amount greater than ${format(
                process.env.REACT_APP_MAX_AMOUNT
              )}`}
              data-background-color='red'
              data-multiline={true}
              data-text-color='#fff'>
              <Info />
            </span>
          )}
        </label>

        <div
          className={`with_dropdown ${
            refs.getValues().sendAmount ? "" : "unfilled"
          } `}>
          <div className='dropdown'>
            {isLoaded ? (
              <DPD
                data={currencies}
                type='large'
                value={send?.label}
                onChange={setSend}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "20px 10px",
                }}>
                <Spinner light />
              </div>
            )}
          </div>
          <small className='estm'>&#8776;</small>
          <CurrencyInput
            ref={refs.sendRef}
            value={refs.values.send}
            prefix={send?.currencySymbol}
            name='send'
            min={0}
            placeholder='Please enter a number'
            defaultValue={defaultValue.sendAmount}
            onValueChange={(value, name) => refs.handleChange({ value, name })}
          />
        </div>
      </div>

      <div className='form-row'>
        <label htmlFor='to'>
          {accountDetails?.accountName?.split(" ")[0]} receives{" "}
          {errors.receive && touched && <small>({errors.receive})</small>}
        </label>

        <div
          className={`with_dropdown ${
            refs.getValues().sendAmount ? "" : "unfilled"
          } `}>
          <div className='dropdown'>
            {isLoaded ? (
              <DPD
                data={[]}
                value={receive?.label}
                onChange={setReceive}
                type={"large"}
                noCaret
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "20px 10px",
                }}>
                <Spinner light />
              </div>
            )}
          </div>
          <small className='estm'>&#8776;</small>
          <CurrencyInput
            ref={refs.receiveRef}
            name='receive'
            prefix={receive?.currencySymbol}
            value={refs.values.receive}
            placeholder='Please enter a number'
            defaultValue={defaultValue.receiveEstimatedAmount}
            min={0}
            onValueChange={(value, name) => refs.handleChange({ value, name })}
          />
        </div>
      </div>

      <div style={{ margin: "100px 0" }}></div>
      <button disabled={!isValid()} className='submit' onClick={handleSubmit}>
        Continue
      </button>
      <ReactTooltip />
    </div>
  );
}

Calculator.propTypes = {};

function checkForHomeCalcEntries(cb) {
  const origin = queryString.parse(window.location.search).origin;
  if (origin && origin.toLowerCase() === "home") {
    //read from session storage
    const homeEntries = checkedJsonStorage("homeEntries", true);

    if (isEmpty(homeEntries)) {
      return;
    }

    const { send: sendCurrency, receive: receiveCurrency, values } =
      homeEntries || {};
    //check if they are both valid entries
    if (isEmpty(sendCurrency) || isEmpty(receiveCurrency)) {
      return;
    }
    //then set state

    //pass safeValues to callback
    cb({
      currencies: { sendCurrency, receiveCurrency },
      values: {
        receiveEstimatedAmount: +values?.receive || 0,
        sendAmount: +values?.send || 0,
      },
    });

    //clear session storage and query param
    sessionStorage.removeItem("homeEntries");
  }
}

export default withProvider(CurrencyProvider)(Calculator)

