import React from "react";
import "./Spinner.scss";
import { LeafySmallLogo } from "../icons";

function Spinner({ light, center, showLogo }) {
  return (
    <div className={`loader ${light ? "light" : ""} ${center ? "center" : ""} ${ showLogo ? "showLogo" : ""}`}>
      {showLogo && (
        <div className='logospin'>
          <LeafySmallLogo />
        </div>
      )}
    </div>
  );
}

export default Spinner;
