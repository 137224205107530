import React from "react";
import "./Info.scss";

function PrivacyPolicy() {
  return (
    <section className='Info'>
      <div className='InfoContent'>
        <h2 className='pageTitle'>Privacy Policy</h2>

        <p className='text'>
          This privacy policy describes how easyshare.africa, our applications
          and platforms uses and protects any information that you give
          Easyshare when you use our website, apps and any of our applications
          and platforms.
        </p>

        <p className='text'>
          Easyshare.africa is committed to ensuring that your privacy is
          protected. Should we ask you to provide certain information by which
          you can be identified when using any of our platforms, then you can be
          assured that it will only be used in accordance with this privacy
          statement. Easyshare may change this policy from time to time by
          updating this page. You should check this page from time to time to
          ensure that you are happy with any changes. <br />{" "}
          <b>This policy is effective from 22nd February 2021.</b>
        </p>

        <p className='text'>
          <strong>What we collect:</strong> We may collect the following
          information: name and job title contact information, including email
          address demographic information, such as postcode, preferences and
          interests, other information relevant to customer surveys and/or
          offers{" "}
        </p>

        <p className='sh-1'>What we do with the information we gather</p>

        <p className='text'>
          We require this information to understand your needs and provide you
          with a better service, particularly for the following reasons:
        </p>

        <p className='text sh-2'>Internal record keeping</p>

        <p className='text'>
          We may use the information to improve our products and services. We
          may periodically send promotional emails about new products, special
          offers or other information which we think you may find interesting,
          using the email address which you have provided
        </p>
        <p className='text'>
          From time to time, we may also use your information to contact you for
          market research purposes. We may contact you by email, phone or mail.
          We may use the information to customise our platforms according to
          your interests.
        </p>

        <p className='text sh-2'>Security</p>

        <p className='text'>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure, we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>

        <p className='text sh-2'>Cookies</p>

        <p className='text'>
          We use technologies, such as cookies (https://www.aboutcookies.org),
          to customise content and advertising, to provide social media features
          and to analyse traffic to the site. We may also share information
          about your use of our site with our trusted social media, advertising
          and analytics partners:
        </p>

        <p className='text sh-2'>
          Facebook.com
          <br />
          Analytics.google.com
        </p>

        <p className='text'>
          A cookie is a small file which asks permission to be placed on your
          computer's hard drive. Once you agree, the file is added and the
          cookie helps analyse web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences. We use traffic log cookies to identify which
          pages are being used. This helps us analyse data about web page
          traffic and improve our platforms in order to tailor it to customer
          needs. We only use this information for statistical analysis purposes
          and then the data is removed from the system. Overall, cookies help us
          provide you with a better platform by enabling us to monitor which
          pages you find useful and which you do not. A cookie in no way gives
          us access to your computer or any information about you, other than
          the data you choose to share with us. You can choose to accept or
          decline cookies.
        </p>

        <p className='text sh-2'>Links to other websites</p>

        <p className='text'>
          Our website, apps and platforms may contain links to other websites,
          apps and platforms of interest. However, once you have used these
          links to leave our site, apps and platforms, you should note that we
          do not have any control over that other website, app or platform.
          Therefore, we cannot be responsible for the protection and privacy of
          any information which you provide whilst visiting such sites, apps and
          platforms and such sites, apps and platforms are not governed by this
          privacy statement. You should exercise caution and look at the privacy
          statement applicable to the website, app or platform in question.
        </p>

        <p className='text sh-2'>Controlling your personal information</p>

        <p className='text'>
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </p>

        <p className='text'>
          Whenever you are asked to fill in a form on the website or app, you
          will always be given the opportunity to unsubscribe or be removed from
          any mailings or marketing. If you have previously agreed to us using
          your personal information for direct marketing purposes, you may
          change your mind at any time by writing to or emailing us at{" "}
          <a
            className='link'
            target='_blank'
            href='mailto:support@easyshare.africa'
            rel='noreferrer'>
            support@easyshare.africa
          </a>
          . We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen.
        </p>

        <p className='text'>
          You may have certain rights under data protection laws in the country
          in which you live, including objection to the processing of your
          personal information or to request that we provide you with a copy of
          your personal information; or to delete, restrict, correct, the
          processing of your personal information.
        </p>

        <p className='text'>
          If you would like a copy of the information held on you, please write
          to:{" "}
          <a
            className='link'
            target='_blank'
            href='mailto:support@easyshare.africa'
            rel='noreferrer'>
            support@easyshare.africa
          </a>
          . A small fee will be payable.
        </p>

        <p className='text'>
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to us as soon as possible at{" "}
          <a
            className='link'
            target='_blank'
            href='mailto:support@easyshare.africa'
            rel='noreferrer'>
            support@easyshare.africa
          </a>
        </p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
