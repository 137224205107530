import React, { useState, useEffect, useCallback, createContext, useMemo, useRef } from "react";
import { baseUrl } from "../utils"
const CurrencyContext = createContext();

function CurrencyProvider({ children }) {
  const [currencies, setCurrency] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const network = useRef("")
  const abortController = useMemo(()=>new AbortController(),[]);
  const signal = abortController.signal;

  const getcurrencySymbol = (name) => {
    const currencySymbol = ''
    switch (name.toUpperCase()) {
      case 'USD':
        currencySymbol = '$'
        break;
      case 'EUR':
        currencySymbol = '£';
        break;
      case 'NGN':
        currencySymbol = '₦';
        break;
      case 'USD':
        currencySymbol = '$';
        break;
      default:
        break;
    }
  }

  const getData = useCallback(async () => {
         
        const currencyUrl = `${baseUrl}/rates/exchange/share${ !!network.current ? `?network=${network.current?.toUpperCase()}` :""}`;
        try {
          let testCurrencies = await fetch(`${baseUrl}/send/exchange-rates?receiveCurrency=NGN`, { signal })

          // let NewCurrencies = await fetch(`${currencyUrl}`,{signal});
          if (testCurrencies.ok) {
            // NewCurrencies = await NewCurrencies.json();
            testCurrencies = await testCurrencies.json()

            // NewCurrencies = NewCurrencies.map((c) => ({
            //   ...c,
            //   label: c.sendDisplayName,
            //   value: c.send,
            //   receive: c.receive.map((r) => ({
            //    ...r,
            //     label: r.displayName,
            //     value: r.name,
            //     rate: +r.rate,
            //   })),
            // }));

            testCurrencies = testCurrencies.map((c) => ({
              ...c,
              label: c.sendCurrency,
              value: c.sendCurrency,
              
              receive: [{
                ...c,
                label: c.receiveCurrency,
                value: c.receiveCurrency,
                rate: +c.rate,
              }]
            }));



            setError(null);
            setCurrency(testCurrencies);
            return Promise.resolve("ok")
          } else {
            setError("Unable to retrieve coins");
          
          }
        } catch (error) {
          setError(error.mesage);
        }
  },[signal,]);

  useEffect(() => {
    getData().then(()=>setIsLoaded(true));
    // update every 10 secs
    const timer = setInterval(getData, 10 * 1000);
    return () =>{  clearInterval(timer); abortController.abort()};
  }, [getData, abortController]);

  const setNetwork = (picked) => network.current = picked;
  return (
    <CurrencyContext.Provider value={{ error, currencies, isLoaded, setNetwork }}>
      {children}
    </CurrencyContext.Provider>
  );
}

export { CurrencyProvider, CurrencyContext };
