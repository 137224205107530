import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./DropDown.scss";

function DropDown({
  value,
  name,
  up,
  data,
  onChange,
  block,
  noCaret,
  title,
  type,
  ghost,
  scroll,
  isInvalid,
  placeholder,
  defaultMessage,
}) {
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);
  const testref = useRef(null);
  const toggleDropDown = () => {
    setIsDropDownOpened(!isDropDownOpened);
  };
  const types = {
    medium: "md", //for smaller width
    large: "full", //for larger width style
  };
  const defaultType = types["medium"];
  let currentLetter = "";

  const handleOnClickOutside = useCallback(
    (ev) => {
      ev.stopPropagation();
      if (!ev.target.matches("dropdownWrapper")) {
        if (isDropDownOpened) setIsDropDownOpened(false);
      }
    },
    [isDropDownOpened]
  );

  useEffect(() => {
    window.addEventListener("click", handleOnClickOutside);
    return () => {
      window.removeEventListener("click", handleOnClickOutside);
    };
  }, [handleOnClickOutside]);

  function handleOnKeyPress(ev) {
    const alpha = "abcdefghijklmnopqrstuvwxyz".split("");
    if (!alpha.includes(ev.key)) return;
    const picked = testref.current.querySelector(`#${ev.key.toUpperCase()}`);
    if (picked) {
      testref.current.scrollTo(0, picked.offsetTop);
    }
  }

  const cx = (...list) => list.join(" ");

  return (
    <div
      className={cx(
        "dropdownWrapper",
        block ? "block" : "",
        types[type] || defaultType
      )}>
      {title && <label>{title}</label>}
      <div
        className={cx(
          "custom-dropdown",
          ghost && "ghost",
          isInvalid && "invalid"
        )}
        onClick={toggleDropDown}
        onKeyPress={handleOnKeyPress}
        tabIndex={0}>
        {isDropDownOpened && (
          <ul
            className={cx(
              "dropDownOption",
              "full",
              scroll && "scroll",
              up ? "up" : ""
            )}
            ref={testref}>
            {data &&
              data.map((item, index) => {
                const { label = "" } = item;
                return (
                  <DropDownItem
                    key={`Menu ${index}`}
                    id={(() => {
                      if (label[0] !== currentLetter) {
                        currentLetter = label[0];
                        return label[0];
                      }
                      return undefined;
                    })()}
                    onClick={
                      onChange
                        ? () => {
                            onChange(item);
                            toggleDropDown();
                          }
                        : void 0
                    }>
                    {label}
                  </DropDownItem>
                );
              })}
          </ul>
        )}
        <p>
          {value || placeholder} {"  "}
          {!noCaret && (
            <span className={isDropDownOpened ? "rotate" : ""}>&#9660;</span>
          )}
        </p>
      </div>
    </div>
  );
}

function DropDownItem({ onClick, children, id }) {
  return (
    <li id={id && id} onClick={onClick}>
      {children}
    </li>
  );
}
DropDown.defaultProps = {
  isInvalid: false,
  placeholder: "--select--",
};
DropDown.propTypes = {
  value: PropTypes.string, //to dispaly value
  data: PropTypes.array, //to options to pick from
  onChange: PropTypes.func, //to handler for selection
  title: PropTypes.string, //to show a label at the top
  block: PropTypes.bool, //to overide the inline-block flow
  ghost: PropTypes.bool, //for transparent background
  scroll: PropTypes.bool, //to prevent the menu from growing when data is large
  type: PropTypes.string, //switch betwwen large or small size, this also affects the dropdown menu
  isInvalid: PropTypes.bool, // for ux experience
  defaultMessage: PropTypes.string, //messgae to displayed when there is no data
};

export default DropDown;
