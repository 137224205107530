import React from 'react';
import Header from "../Header";
import "./MultiStep.scss";

function MultiStep ( { pages = [], page, changePage, verifiedPages, title, isRequest } ) 
{

  return (
    <div className='MultiStep'>
      <Header isRequest={ isRequest } />

      <section className='formWrap'>
        <div className='indicators'>
          { pages.map( ( p, i ) => (
            <button
              key={ i }
              onClick={ () => changePage( i, verifiedPages[ i ] ) }
              className={ `indic ${ i === page ? "active" : "" }` }></button>
          ) ) }
        </div>
        <h3 className='formTitle'>{ title }</h3>
        { pages[ page ] }
      </section>
    </div>
  );
}

export default MultiStep;
