import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { Link as ScrollLink, scroller, Events } from "react-scroll";
import logo from "../../assets/images/logo.svg";
import "./NavLogo.scss";

const links =
  [
    {
      target: "Home",
      text: "Home",
      offset: 0
    },

    {
      target: "Why",
      text: "Why choose us",
      offset: -10
    },

    {
      target: "How",
      text: "How it works",
      offset: -95
    }
    ,
    {
      target: "Testimonial",
      text: "Testimonial",
      offset: -80
    },
    // {
    //   target: "Share",
    //   text: "$SHARE Token",
    //   offset: -25
    // }
  ];

const linkProps =
{
  spy: true,
  smooth: true,
  duration: 500,
  activeClass: "activeLink"
};

function Index ( { isCenter, maxWidth } )
{
  const { pathname, search, state } = useLocation();
  const [ open, setIsOpen ] = useState( false );
  const [ scrolled, setScr ] = useState( false );

  useEffect( () =>
  {
    const changeBg = () =>
    {
      if ( window.scrollY > 20 )
      {
        setScr( true );
      }
      else
      {
        setScr( false );
      }
    };

    const resize = window.addEventListener( "resize", () => setIsOpen( false ) );

    window.addEventListener( "scroll", changeBg );
    Events.scrollEvent.register( 'end', () =>
    {
      setIsOpen( false );
    } );

    return () =>
    {
      window.removeEventListener( "scroll", changeBg );
      window.removeEventListener( "resize", resize );
      Events.scrollEvent.remove( 'end' );
    };
  }, [] );

  useEffect( () =>
  {
    if ( state )
    {
      const target = links.find( li => li.target === state.el );
      scroller.scrollTo( state.el, {
        duration: 500,
        delay: 100,
        smooth: true,
        offset: target?.offset
      } );
    }
  }, [ state ] );


  const toggle = () => setIsOpen( !open );

  const isHome = pathname === "/" || search.includes( "rId" );

  return (
    <nav className={ `Navlogo ${ scrolled ? "scrolled" : "" }` }>
      <div className='pagewrap'>
        <div className="innerWrapper">
          <NavLink className='homelink' to='/'>
            <img src={ logo } alt='Easy share logo' />
            <span className="beta">BETA</span>
          </NavLink>

          <button onClick={ toggle } className="menu">
            <i className={ `fas fa-2x ${ open ? "fas fa-times" : "fa-bars" } ` }></i>
          </button>

          <ul className={ `links ${ open ? "open" : "" }` } onMouseLeave={ toggle }>
            {
              !isHome ?
                (
                  links.map( link => (
                    <li key={ link.target }>
                      <NavLink
                        to={ {
                          pathname: "/",
                          hash: `#${ link.target }`,
                          state: { el: link.target }
                        } }
                      >
                        { link.text }
                      </NavLink>
                    </li>
                  ) )
                ) :
                ( links.map( link => (
                  <li key={ link.target }>
                    <ScrollLink
                      to={ link.target }
                      { ...linkProps }
                      offset={ link.offset }
                    >
                      { link.text }
                    </ScrollLink>
                  </li>
                ) ) )
            }

            {/* <li>
              <NavLink activeClassName="activeLink" to="/affiliate">
                Affiliate
              </NavLink>
            </li> */}
          </ul>

        </div>
      </div>
    </nav>
  );


}


export default Index;
