import React from 'react';
import "./Pagination.scss";

function Pagination2 ( { totalPages, currentPage, getPage } ) 
{
    const generatePages = () =>
    {
        const links = [];
        for ( let i = 0; i < totalPages; i++ ) 
        {
            const el = currentPage !== i ? (
                <button key={ i }
                    onClick={ () => getPage( i + 1 ) }
                    className="buttonPage"
                >
                    { i + 1 }
                </button>
            ) : <span className="buttonPage activeBtn" key={ i }>{ i + 1 }</span>;


            links.push( el );
        }

        return links;
    };
    return (
        <div className="pagination2">
            { generatePages() }
        </div>
    );
}

export default Pagination2;
