import React, { useState } from 'react'

import './Login.scss'

import { Header, NavLogo } from "../../../components";
import { useHistory } from 'react-router-dom';
import { useUserAction } from '../Factory';


function Login(props) {
    const [loginDetails, setLoginDetails] = useState({
        email: '',
        password: ''
    })

    const history = useHistory();

    const userAction = useUserAction();

    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState("");

    const [show, setShow] = useState(false)

    const { email, password } = loginDetails

    const onChange = (e) => setLoginDetails(() => ({ ...loginDetails, [e.target.name]: e.target.value }))

    const togglePassword = (e) => setShow(() => !show)


   function handleSubmit(e){
       e.preventDefault()
        setLoading(true);
        loginDetails.email = loginDetails.email?.toLowerCase();
        userAction.login(loginDetails).then( status => {
          setLoading(false);
          history.push("/affiliate/dashboard");
        }).catch( err => {
          setLoading(false);
          setServerError(Array.isArray(err) ? err.join(",") : err);
        });
    };

    const isValid = () => (email && password);
    return (
      <div className='Affiliate_login'>
        <NavLogo isCenter />
        <div className='MultiStep'>
          <Header />

          <section className='formWrap'>
            <h3 className='formTitle'>Login</h3>

            {serverError && (
              <span
                className='error'
                style={{ textAlign: "center", display: "block" }}>
                {serverError}
              </span>
            )}

            <div className='form-row'>
              <label htmlFor='email'>Email</label>
              <input
                type='email'
                name='email'
                id='email'
                placeholder='email'
                required
                value={email}
                onChange={onChange}
              />
            </div>

            <div className='form-row'>
              <label htmlFor='password'>Password</label>
              <input
                type={`${show ? "text" : "password"}`}
                name='password'
                id='password'
                placeholder='password'
                required
                value={password}
                onChange={onChange}
              />
              <i
                className={`far ${show ? "fa-eye-slash" : "fa-eye"}`}
                id='togglePassword'
                onClick={togglePassword}></i>
            </div>

            <span className='forgot link'
              onClick={()=>history.push('/affiliate/forgot-password')} >
              Forgot password?
            </span>

            <button
              onClick={handleSubmit}
              type='submit'
              className='submit'
              disabled={!isValid() || loading}>
              Log in
            </button>
            <p className='Alt'>
              Don't have an account yet?{" "}
              <span className='link'
              onClick={()=>history.push('/affiliate/signup')}
              >Sign up</span>
            </p>
          </section>
        </div>
      </div>
    );
}

Login.propTypes = {

}

export default Login

