import React, { useEffect, useState, useCallback,useRef } from "react";
import {useLocation} from 'react-router-dom'

import {animateScroll as scroll} from 'react-scroll'
import up from "../../../../assets/images/Polygonup.png";
import loadicon from "../../../../assets/images/loadicon.svg";
import side from "../../../../assets/images/Polygon.png";
import { baseUrl } from "../../../../utils";

function Bank({ value, onSubmit, isSubmitting, error }) {
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, /*verified*/ setVerified] = useState(false);
  const [accountNumber, setAccountNumber] = useState(
    value?.accountNumber || ""
  );
  const [bankCode, setBankCode] = useState(value?.bankCode || "");
  const [banks, setBanks] = useState([]);
  const [erroracc, setErroracc] = useState(false);
  const [accountName, setAccountName] = useState(value?.accountName || "");
  const [bankName, setBankName] = useState(value?.bankName || "");

  const bank = useRef()

  function handleClick() {
    setClicked((prev) => !prev);
  }

  function handleChange(e) {
    setAccountNumber(e.target.value);
    //  setVerified((prev) => prev);
  }
  function handleBankCode(e) {
    const value = e.target.value;
    //get bankName
    if (banks.length) {
      let picked = banks.find(({ code }) => code === value);
      setBankName(picked?.name);
    }
    setBankCode(value);
  }

  const verify = useCallback(async () => {
    setLoading((prev) => !prev);
    try {
      const response = await fetch(
        `${baseUrl}/api/utils/bank/verifyAccount?accountNumber=${accountNumber}&bankCode=${bankCode}`
      );
      const data = await response.json();

      if (data) {
        setLoading(false);
        setAccountName(data?.account_name || "");
      }
    } catch (error) {
      setLoading(false);
    }
  }, [accountNumber, bankCode]);

  async function getBanks() {
    try {
      const response = await fetch(
        `${baseUrl}/api/utils/banks/NGN`
      );
      const data = await response.json();

      if (data.status) {
        setBanks(data.data);
      }
    } catch (error) {
      console.log("poor network");
    }
  }

  async function submitForm(e) {
    e.preventDefault();
    if(accountNumber.length <10) return;
      onSubmit(
        {
          bankCode,
          accountNumber,
          accountName,
          bankName,
        },
        handleClick
      );
   
  }

  useEffect(() => {
    accountNumber.length === 10
      ? setVerified((prev) => !prev)
      : setVerified(false);
  }, [accountNumber]);

  useEffect(() => {
    getBanks();
  }, []);

const location = useLocation()
useEffect(() => {
      if (location.state?.fromDashboard) {
      handleClick()
      scroll.scrollTo(bank.current.scrollHeight)
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  useEffect(() => {
    if (accountNumber.length === 10) {
      verify();
    }
  }, [accountNumber, verify]);

  function showError() {
    setErroracc((prev) => !prev);
  }
  useEffect(()=>{
    accountNumber.length===10 && setErroracc((prev) => false);
  },[accountNumber.length])
  return (
    <div ref={bank} className="dropdown_section" id='bank'>
      <button
        className={`dropdown ${clicked && "active"}`}
        onClick={handleClick}
        // onBlur={handleBlur}
      >
        {!!!value?.accountName ? (
          <p>Add a Bank</p>
        ) : (
          <>
            <p style={{ textTransform: "lowercase" }} className="cut-text">
              {`${value?.bankName} - ${value?.accountName}`}
            </p>
          </>
        )}
        {!!!accountName || clicked ? (
          <img src={clicked ? up : side} className="icon" alt="caret" />
        ) : (
          <p id="edit">Edit</p>
        )}
      </button>
      <div id="dropdown_content" className={`${clicked ? "show" : "hide"}`}>
        <form>
          <div className="form_group">
            <label htmlFor="bankCode">Bank Name </label>
            <select
              className="select-css"
              disabled={banks.length > 0 ? false : true}
              onChange={handleBankCode}
              value={bankCode}
            >
              <option>Select bank</option>
              {banks &&
                banks.map((bank, index) => (
                  <option
                    className="banks"
                    key={bank.id}
                    value={bank.code}
                    name={bank.name}
                  >
                    {bank.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="form_group">
            <label htmlFor="accountNumber">Account Number </label>
            <input
              type="tel"
              name="accountNumber"
              id="accountNumber"
              placeholder="e.g 2020202020"
              value={accountNumber}
              onChange={handleChange}
              disabled={banks.length > 0 ? false : true}
              onBlur={() => accountNumber.length < 10 && showError()}
            />
          </div>
          <div className={`verify_message`}>
            {loading === true ? (
              <div>
                <img
                  src={loadicon}
                  style={{
                    width: "18px",
                    height: "18px",
                    marginTop: "10px",
                  }}
                  alt="loading"
                />
                <p id="verify_text">verifying</p>
              </div>
            ) : (
              <p>{accountName}</p>
            )}
          </div>
          
            <div className="error_message">
            { erroracc &&  <p id="error_text">account number isnt complete yet</p>}
            </div>
         
          <div className="form_group">
            <button
              className="submit_button"
              onClick={submitForm}
              disabled={accountNumber.length === 10 ? isSubmitting : true}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

Bank.propTypes = {};

export default Bank;
