import React, { useState, useEffect } from 'react';
import "./Cookies.scss";

function Cookies ( { grantConsent } )
{
    const [ show, setShow ] = useState( false );

    const closeCookie = () =>
    {
        localStorage.setItem( "easyPixel", "accept" );
        grantConsent();
        setShow( false );
    };

    useEffect( () => 
    {
        const accepted = localStorage.getItem( "easyPixel" );

        if ( !accepted && !show )
        {
            setShow( true );
        }
    }, [ show ] );

    if ( !show ) return <></>;

    return (
        <div className="cookies">
            <h2>Cookie Consent</h2>

            <p>We use technologies, such as cookies (https://www.aboutcookies.org), to customise content and advertising, to provide social media features and to analyse traffic to the site.</p>

            <p>By continuing to browse or by clicking ‘Accept’, you agree to the storing of cookies on your device to enhance your site experience and for analytical purposes.</p>

            <button onClick={ closeCookie }>Accept and Close</button>
        </div>
    );
}

export default Cookies;
