import React from "react";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import { GridContainer, GridBox } from "../../../../components";
import { format } from "../../../../utils";
import { useUserState } from "../../Factory/User/User.context";

import "./Dashboard.scss";
import AddBankDetails from "../AddBankDetails";

const Dashboard = ({match,reqCanceled}) => {
  const { user, commissions, referrals } = useUserState();
  const { totalElements } = referrals;
  const refererLink =
    process.env.REACT_APP_BASEURL + "?affiliateId=" + user?.refererId;
  const [indic8Cpd, setIndic8Cpd] = React.useState(false);
  const inputRef = React.useRef(null);

  function handleCopy() {
    if (inputRef.current) {
      inputRef.current.select();
      inputRef.current.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      setIndic8Cpd(true);
    }
  }

  React.useEffect(() => {
    let timer = null;
    if (indic8Cpd) {
      timer = setTimeout(() => setIndic8Cpd(false), 1000);
    }
    return () => clearTimeout(timer);
  }, [indic8Cpd]);

  const userLinkId = user?.id ? `?userId=${user.id}` : "";


  return (
    <div className='dashboard'>
      <section className='head'>
        <h3>Welcome {user?.name}</h3>
        <div className='buttons'>
          <Link to={`/send${userLinkId}`}>Send Money</Link>
          <Link to={`/receive${userLinkId}`}>Receive Money</Link>
        </div>
      </section>
      <GridContainer>
        <GridBox title='TOTAL REVENUE'>
          <div className='info_count'>
            <h2>₦ 0</h2>
          </div>
        </GridBox>
        <GridBox title='AFFILIATE BALANCE'>
          <div className='info_count'>
            <h2>₦{format(commissions?.total || 0)}</h2>
            <button disabled={!!!commissions?.total}>Withdraw</button>
          </div>
        </GridBox>
        <GridBox title='MY REFERRALS'>
          <div className='info_count'>
            <h2>{totalElements}</h2>
            <Link className='info_btn' to={`${match.path}/referrals`}>
              view
            </Link>
          </div>
        </GridBox>
      </GridContainer>

      {!user?.bankDetails && reqCanceled && (
        <AddBankDetails match={match} onCancelClick={() => {}} />
      )}

      <div className='network'>
        <section className='network__table'>
          <h3 className='netHead'>Grow Your Network</h3>
          <p>
            Share your link and earn up to N5 per transaction when you refer
            people to use Easyshare to send or receive money
          </p>

          <div className='invite'>
            <div className='inviteLink_box'>
              <input
                type='text'
                value={refererLink}
                readOnly
                ref={inputRef}
                className='visuallyHidden'
              />
              <h3>Invite By Link</h3>
              {!user?.isConfirmed || !user?.refererId ? (
                <p>
                  please verify your account inorder to use your referer Link
                  and start earning
                </p>
              ) : (
                <>
                  <Link to={refererLink}>{refererLink}</Link>
                  <button onClick={handleCopy} className='copy'>
                    {indic8Cpd ? "Copied" : "Copy Link"}
                  </button>
                </>
              )}
            </div>

            <div className='invite_qr'>
              <h3>Invite By Qr</h3>
              <p>
                Anyone who scans your QR code will become your affiliate on
                EASYSHARE
              </p>
              {!user?.refererId && !user?.isConfirmed ? (
                <p>
                  please verify your account inorder to use your referer Link
                  and start earning
                </p>
              ) : (
                <QRCode value={refererLink} size={122} />
              )}
            </div>
          </div>
        </section>
      </div>

      <div className='services'>
        <div>
          <h3>Affiliate Program Terms of Service</h3>
          <div>
            <p>
              Learn more about using Easyshare’s Affiliate program on our Terms
              of Service Page
            </p>
            <button>Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
