/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { Header, NavLogo } from "../../../../components";
import {string as yupString} from 'yup';
import {Spinner} from '../../../../components'
import {useUserAction} from '../../Factory'


import "./PasswordRecovery.scss"

const PasswordRecovery = ({match}) => {
    const [mailError,setMailError] = useState(false)
    const [loading,setLoading] = useState(false)
    const [serverError, setServerError] = useState("");
    const [success,setSuccess] = useState(false)
    const [recoveryMail, setRecoveryMail] = useState({
        email:{
            value:'',
            error:'required'
        }
    })

    const { email } = recoveryMail

    const userAction = useUserAction();

    const validationSchema = {
        email: yupString()
        .trim()
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "invalid email format"
        )
        .required("email is required"),
    }

    const validateMail = (name,value) => {
        let error = "";
        try {
            value = validationSchema[name]?.validateSync(value);

        } catch (err) {
          if (err.name === "ValidationError") {
            error = err.errors.join(",");
          }
        }
        return { value, error };
    }

    const onChange = (e) => {
        const {name,value} = e.target
        const{value:val,error} = validateMail(name,value)
        setRecoveryMail(() => ({
            ...recoveryMail,
            [name] : {value:val.trim(), error}
        }) )
    }

      // make the submit button appear disabled if there are any errors with the email field
  function isValid() {
    return !(email.error);
  }

  const callbackUrl = process.env.REACT_APP_BASEURL + 'affiliate/create-new-password'

    const handleSubmit = (e) => {
        e.preventDefault()
        const {value} = email

        // set an error state if there is any
        if (email.error) {
            setMailError(true)
            setTimeout(() => {
              setMailError(false)
            }, 5000)
            return;
          }
      
        setLoading(true);
        setServerError("");
        userAction
        .forgotPassword({
            email: value,
            callbackUrl
        })
          .then((status) => {
            setLoading(false);
            setSuccess(true)
          })
          .catch((err) => {
            setLoading(false);
            setSuccess(false)
            setServerError(Array.isArray(err) ? err.join(",") : err);
          });

    }

    return (
        <div className='Affiliate_login'>
            <NavLogo isCenter />
            <div className="MultiStep">
                <Header />

                <section className="formWrap">
                    <h3 className="formTitle">Password Recovery</h3>

                    {" "}
                    {serverError && (
                    <span className='error'
                    style={{ textAlign: "center", display: "block" }}>
                    {serverError}
                    </span>
                    )}
{
    success && !serverError ? 
                    <div className="success-message">
                        <i className="far fa-check-circle"></i>
                        <h4>
                            Please check your mail inbox.
                        </h4>
                        <p>
                            A password rest link has been to sent to {email.value}.
                        </p>
                    </div>
                    :
                    (
                    <div>
                    <header>Enter your registered email address below</header>

                    <div className="form-row">
                        <label htmlFor="email">
                            Email
                            {
                                mailError && email.error && (
                                    <span className='error'>  ({email.error})</span>
                                )
                            }
                            </label>
                        <input type="email" name="email" id="email" placeholder='email' required value={email.value} onChange={onChange} />
                    </div>

                    <button type="submit"
                    className='submit'
                    onClick={handleSubmit}
                    id={!isValid() ? 'disabled' : ''}
                    >
                        {loading && <Spinner light />} Submit
                        </button>
                        </div>
                        )
}
                </section>
            </div>
        </div>
    )
}

export default PasswordRecovery
