import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../../../components/icons";
import { useUiState } from "../../../../_helpers";
import Gravatar from "../Gravatar";
import SidebarItem from "./SidebarItem/SidebarItem";

//import PropTypes from 'prop-types'

function Sidebar ( { match, location } )
{
  const { UiState, UiActions } = useUiState();

  let sidebarClass = "affiliate_sidebar";
  if ( UiState.isSideBarOpened )
  {
    sidebarClass = "affiliate_sidebar active";
  }
  const navigations = [
    {
      name: "overview",
      label: "Overview",
      link: "",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "Referrals",
      label: "Referrals",
      link: "referrals",
      icon: "fas fa-users",
    },
    {
      name: "transactions",
      label: "Transactions",
      link: "transactions",
      icon: "fas fa-money-check-alt",
    },
    {
      name: "steps",
      label: "How it Works",
      link: "steps",
      icon: "fas fa-info-circle",
    },
    {
      name: "settings",
      label: "Settings",
      link: "settings",
      icon: "fa fa-cog",
    },
  ];

  const signout = {
    name: "sign out",
    label: "Sign out",
    link: "signout", //TODO replace with action 
    icon: "fa fa-power-off",
  };
  const mobilebackdrop = (
    <div className='backdrop' onClick={ UiActions.toggleSidebar }></div>
  );
  return (
    <>
      { UiState.isSideBarOpened && mobilebackdrop }
      <div className={ sidebarClass }>
        <div className='close-icon' onClick={ UiActions.toggleSidebar }>
          <i className='fas fa-times'></i>
        </div>
        <div className='logo'>
          <Link to="/">
            <Logo />
          </Link>
        </div>


        {/* USER GRAVATAR HERE */ }
        <Gravatar />
        {/* PAGE LINKS HERE */ }
        <nav>
          <ul className='navItem'>
            { navigations.map( ( item, index ) => (
              <SidebarItem
                key={ `${ item.name }${ index }` }
                item={ item }
                location={ location }
                path={ match?.path }
              />
            ) ) }
            <div className='nav_bottom' style={ { marginTop: 20 } }>
              <SidebarItem
                key={ `signout_${ navigations?.length }` }
                item={ signout }
                location={ location }
                path={ match?.path }
              />
            </div>
          </ul>
        </nav>
      </div>
    </>
  );
}

Sidebar.propTypes = {};

export default Sidebar;
