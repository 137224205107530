import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import PropTypes from "prop-types";
import { ReactComponent as Check } from "../../assets/images/check.svg";
import "./Form.scss";
import { makeReq } from "../../utils";
import { BackBtn } from "../index";

function OTP({ onResend, onVerified, email, customerInfo, type, back, resendLoading }) {

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setIsVerified] = useState(false);
  const [verifyError, setVerifyError] = useState("");
  const [verifyData, setVerifyData] = useState(null);
  //TIMER STATE 
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    let timerId = setInterval(() => {
      if (seconds === 0 && minutes === 0) {
        clearInterval(timerId);
        setTimedOut(true);
        setVerifyError("otp expired");
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0 && minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
        return;
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
    //eslint-disable-next-line
  });

  function formatedTime() {
    let sec = seconds < 10 ? `0${seconds}` : seconds;
    let min = minutes < 10 ? `0${minutes}` : minutes;


    return (
      <p className='timer'>
        OTP expires in <span>{min} : {sec}</span>
      </p>
    );
  }

  function restartTimer() {
    setMinutes(1);
    setSeconds(0);
  }

  function resend() {
    //restart timer
    restartTimer();
    //call resend call back
    setVerifyError("");
    setTimedOut(false);
    onResend();
    setValue("");
  }

  //Auto verify OTP
  useEffect(() => {
    if (value.length < 6) return;
    setLoading(true);
    makeReq("cart/verify/complete", {
      method: "POST",
      body: JSON.stringify({ email: email, code: value, type })
    }).then((resp) => {
      setIsVerified(true);
      setVerifyData(resp.data);
      setLoading(false);
    }).catch(err => {
      setVerifyError(err.message || err);
      setLoading(false);
    });
  }, [value, email, customerInfo, type]);

  function otpView() {
    return (
      <>
        <BackBtn to={back} />
        <div className='otpview'>
          <div className='mess'>
            {!verifyError && (
              <p className='formMessage '>
                An OTP code has been sent to your email address{" "}
                <span className='emph'>{customerInfo?.email}</span>, please
                enter the code below to continue
              </p>
            )}
          </div>
          {loading || resendLoading ? (
            <p className='spinner'>
              <i className='fa fa-spinner fa-spin'></i>
            </p>
          ) : (
            <OtpInput
              value={value}
              onChange={setValue}
              numInputs={6}
              containerStyle={`otp__box ${verifyError ? "error" : ""}`}
              isInputNum
            />
          )}

          {!loading && verifyError && (
            <span className='error'>{verifyError}</span>
          )}

          {!timedOut && formatedTime()}

          {timedOut ? (
            <button className='submit' type='submit' onClick={resend}>
              Resend
            </button>
          ) : (
            <p style={{ cursor: 'pointer'}} className='resend' onClick={resend}>
              <u>Didn’t get the code? click here to resend</u>
            </p>
          )}
        </div>
      </>
    );
  }

  function Verified() {
    return (
      <div className="verified">
        <p>Your email address has been verified</p>
        <div className='verify_icon'>
          <Check />
        </div>
        <p >We’ll keep you notified on the progress <br /> of your transaction</p>
        <button className="submit" type="submit" onClick={() => onVerified(verifyData)}>continue</button>
      </div>
    );
  }



  return (
    <div>
      <p className='formMessage'>Let’s get you notification ready</p>
      {
        !verified ? otpView() : <Verified />
      }
    </div>
  );
}

OTP.propTypes = {
  onResend: PropTypes.func,
  onVerified: PropTypes.func,
};

OTP.defaultProps = {
  onResend: () => { },
  onVerified: () => { }
};
export default OTP;
