import React from "react";
import "./Grid.scss";

export const GridContainer = ({ children }) => (
  <div className='grid__comp'>{children}</div>
);

export function GridBox({ title, children, className }) {
  return (
    <div className={`grid__comp__box  ${className}`}>
      <div className='box_title'>
        <h3>{title}</h3>
      </div>
      <div className='box_body'>{children}</div>
    </div>
  );
}
