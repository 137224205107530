import React from "react";

const TransactionContext = React.createContext();
const TransactionDispatchContext = React.createContext();

const initialState = {
  accountDetails: {
    accountName: "",
    accountNumber: "",
    bankCode: "",
    bankName: "",
    accountNumberType: "",
    country: "NGN",
    phone: "",
    code: ""
  },
  customerInfo: {
    name: "",
    email: "",
  },
  paymentMethod: "",
  linkRequestId: "",
  acceptedTermsAndCondition: true,
  isSubscribed: false,
  payWithCrypto: "",
  receiveCurrency: "",
  sendCurrency: "",
  receiveEstimatedAmount: 0,
  sendAmount: 0,
  phoneNumber: "",
  transactionType: "BUY",
  tokenDto: null,
  affiliateId: "",
  userId: "",
};

function reducer ( state, action )
{
  switch (action.type) {
    case "SET_ACCOUNT_DETAILS":
      return {
        ...state,
        accountDetails: { ...state.accountDetails, ...action.accountDetails },
      };
    case "SET_CUSTOMER_DETAILS":
      const { customerInfo, acceptedTermsAndCondition, isSubscribed } =
        action.payload;
      return {
        ...state,
        customerInfo,
        acceptedTermsAndCondition,
        isSubscribed,
      };
    case "SET_AMOUNT_INFO":
      return { ...state, ...action.payload };

    case "SET_LINKREQUESTID":
      return { ...state, linkRequestId: action.linkRequestId };
    case "SET_PAYMENT_METHOD":
      return { ...state, paymentMethod: action.paymentMethod };
    case "SET_PHONENUMBER":
      return { ...state, phoneNumber: action.phoneNumber };
    case "SET_TOKEN_DTO":
      return { ...state, tokenDto: action.tokenDto };
    case "SET_USERID":
      return { ...state, userId: action.userId };
    case "SET_AFFILIATE_ID":
      return { ...state, affiliateId: action.affiliateId };
    case "SET_TRANSACTIONTYPE":
      const { fromReq } = action;
      //if from request link retain account details, if not clear state
      const newState = fromReq
        ? {
            ...initialState,
            accountDetails: state.accountDetails,
            linkRequestId: state.linkRequestId,
          }
        : initialState;
      //set transactiontype
      return { ...newState, transactionType: action.transactionType };

    default:
      return state;
  }
}

class TransactionAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  clearOldAccount = (field, clearFields = true) => {
    let state = {};
    switch (field) {
      case "accountNumber":
        state = { accountName: "" };
        break;
      case "country":
        if (clearFields) {
          state = {
            accountName: "",
            accountNumber: "",
            bankCode: "",
            bankName: "",
          };
        }
        break;
      case "bankCode":
        if (clearFields) {
          state = { accountName: "", accountNumber: "" };
        }
        break;
      default:
        break;
    }

    return state;
  };

  setAccountDetails = (accountDetails) => {
    this.dispatch({ type: "SET_ACCOUNT_DETAILS", accountDetails });
  };

  setCustomerDetails = (Info) => {
    this.dispatch({ type: "SET_CUSTOMER_DETAILS", payload: Info });
  };

  setAmountInfo = (Info) => {
    this.dispatch({ type: "SET_AMOUNT_INFO", payload: Info });
  };

  setTransactionType = (transactionType, fromReq) => {
    this.dispatch({ type: "SET_TRANSACTIONTYPE", transactionType, fromReq });
  };

  setAccountField = (field, value, clearFields = true) => {
    const cleared = this.clearOldAccount(field, clearFields);
    this.setAccountDetails({ ...cleared, [field]: value });
  };

  setLinkRequestId = (linkRequestId) => {
    this.dispatch({ type: "SET_LINKREQUESTID", linkRequestId });
  };

  setPaymentMethod = (paymentMethod) => {
    this.dispatch({ type: "SET_PAYMENT_METHOD", paymentMethod });
  };

  setPhoneNumber = (phoneNumber) => {
    this.dispatch({ type: "SET_PHONENUMBER", phoneNumber });
  };

  setTokenDto = (tokenDto) => {
    this.dispatch({ type: "SET_TOKEN_DTO", tokenDto });
  };

  setAffiliateId = (affiliateId) => {
    this.dispatch({ type: "SET_AFFILIATE_ID", affiliateId });
  };

  setUserId = ( userId ) => {
    this.dispatch({ type: "SET_USERID", userId });
  };

  initiateVerify = (state) => {};

  completeVerify = (otp) => {};
}

function TransactionProvider ( { children } )
{
  const [ state, dispatch ] = React.useReducer( reducer, initialState );

  return (
    <TransactionContext.Provider value={ state }>
      <TransactionDispatchContext.Provider value={ dispatch }>
        { children }
      </TransactionDispatchContext.Provider>
    </TransactionContext.Provider>
  );
}

function useTransactionState ()
{
  const context = React.useContext( TransactionContext );
  if ( context === undefined )
  {
    throw new Error(
      "useTransactionState must be used within a TransactionProvider"
    );
  }
  return context;
}

function useTransactionDispatch ()
{
  const context = React.useContext( TransactionDispatchContext );
  if ( context === undefined )
  {
    throw new Error(
      "useTransactionDispatch must be used within a TransactionProvider"
    );
  }
  return context;
}
function useTransactionAction ()
{
  const tDispatch = useTransactionDispatch();
  const Taction = React.useMemo( () => new TransactionAction( tDispatch ), [
    tDispatch,
  ] );
  return Taction;
}

export
{
  useTransactionState,
  TransactionProvider,
  useTransactionDispatch,
  TransactionAction,
  useTransactionAction,
};
