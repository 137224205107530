import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
function Testimonial ( { data } )
{
  return (
    <section className='testimonial'>
      { data?.length
        ? data.map( ( { color, text, img, name }, i ) => (
          <div className='testimonial__box' key={ `${ name }${ color }${ i }` }>
            <blockquote style={ { color: color ? color : "#0000" } }>
              <p>{ text }</p>
            </blockquote>
            <div className="user-name-pic">
              <img src={ img } alt={ `${ name }_gravatar` } />
              <h4>{ name }</h4>
            </div>

          </div>
        ) )
        : null }
    </section>
  );
}

Testimonial.propTypes = {
  data: PropTypes.array
};

export default Testimonial;
