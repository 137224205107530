import React from "react";
import AccordionList from "./AccordionList";
import "./index.scss";

export default function Accordion({ data, style }) {
  return (
    <ul className='accordions' style={style}>
      {data &&
        data.map((val, index) => (
          <AccordionList
            key={`${val.title.substring(0, index)}${index}`}
            {...val}
          />
        ))}
    </ul>
  );
}

Accordion.propTypes = {};
export { AccordionList };
