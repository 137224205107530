import { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import 
{
  Home,
  Send,
  Receive,
  NotFound,
  PrivacyPolicy,
  TermandConds,
  Faq,
  Affiliates,
} from "./pages";
import { UIProvider, TransactionProvider } from "./_helpers";
import
  {
    ScrollToTop,
    ErrorBoundary,
    OfflineDetector,
    NavLogo,
    Footer,
    Cookies
  } from "./components";

const advancedMatching = { em: 'some@email.com' };
const options =
{
  autoConfig: true,
  debug: false,
};

function App ()
{
  useEffect( () => 
  {
    ReactPixel.init( process.env.REACT_APP_DISABLE_PIXELID, advancedMatching, options );
    ReactPixel.revokeConsent();

  }, [] );

  const grantConsent = () => ReactPixel.grantConsent();

  return (
    <ErrorBoundary>
      <OfflineDetector />
      <TransactionProvider>
        <UIProvider>
          <Switch>
            <Route path='/send'>
              <ScrollToTop>
                <NavLogo isCenter />
                <Send />
              </ScrollToTop>
            </Route>
            <Route exact path='/receive'>
              <ScrollToTop>
                <NavLogo isCenter />
                <Receive />
              </ScrollToTop>
            </Route>
            <Route exact path='/terms'>
              <ScrollToTop>
                <NavLogo maxWidth />
                <TermandConds />
              </ScrollToTop>
            </Route>
            <Route exact path='/privacy'>
              <ScrollToTop>
                <NavLogo maxWidth />
                <PrivacyPolicy />
              </ScrollToTop>
            </Route>
            <Route exact path='/faqs'>
              <ScrollToTop>
                {/* <NavLogo maxWidth /> */ }
                <Faq />
              </ScrollToTop>
            </Route>
            <Route path='/affiliate' component={ Affiliates } /> 
            <Route exact path='/'>
              <ScrollToTop />
              <NavLogo />
              <Home />
            </Route>
            <Route exact path='*'>
              <ScrollToTop>
                <NavLogo isCenter />
                <NotFound />
              </ScrollToTop>
            </Route>
          </Switch>
          <Footer />
        </UIProvider>
      </TransactionProvider>

      <Cookies grantConsent={ grantConsent } />
    </ErrorBoundary>
  );
}

export default App;
