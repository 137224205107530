export const faqData = [
  {
    title: "What is Easyshare?",
    content: `<p>
            Easyshare is an instant crypto platform that allows you to send and
            receive money using crypto assets in minutes. No sign-up or login
            required.
          </p>`,
  },
  {
    title: "How do I send or receive money on Easyshare?",
    content: ` <h3>How to send money using crypto</h3>
          <ul class='faq_step'>
            <li>Click on “Send Money”</li>
            <li>Select and input recipient account or phone number</li>
            <li>Input amount and select crypto to send</li>
            <li>Verify your email for notifications</li>
            <li>Send crypto and recipient will be credited</li>
          </ul>
          <h3>How to receive money using crypto</h3>
          <ul class='faq_step'>
            <li>Click on “Receive money”</li>
            <li>Select and input your account or phone number</li>
            <li>Verify your email for notifications</li>
            <li>Setup notifications</li>
          </ul>`,
  },
  {
    title: "What is OTP?",
    content: ` <p>
            OTP means a One Time Password and is a 6-digit number Easyshare
            sends to your mobile phone or email at the start of a transaction
            for the sole purpose of notifying you of the progress of your
            transaction from start to finish.
          </p>`,
  },
  {
    title: "What currencies can I use to share crypto?",
    content: ` <ul class="coin_list">
            <li>USD</li>
            <li>GBP</li>
            <li>EUR</li>
            <li>ZAR</li>
          </ul>`,
  },
  {
    title: "What crypto asset can I use to pay?",
    content: ` <ul class="coin_list">
            <li>BTC</li>
            <li>ETH</li>
            <li>USDT</li>
            <li>LTC</li>
          </ul>`,
  },
  {
    title: "What country is Easyshare available in?",
    content: ` <p>
            Easyshare is currently available in Nigeria. Expansion plans to
            other African countries are in the works. We will let you know as
            soon as we go live in other countries. Make sure you subscribe to
            our newsletter here
          </p>`,
  },
  {
    title: "Why do I need to enter my email?",
    content: `<p>
            In order to send or receive crypto, you will need to enter your
            email address or phone number. It is a necessary step for
            transaction identification required by our payment partners. Also,
            we need to notify you on the status of your transaction.
          </p>`,
  },
  {
    title: "What is a wallet address?",
    content: `<p>
            A wallet is a digital place where you can store crypto coins and
            tokens. A wallet address is a randomly generated combination of
            digits and letters that is associated with a particular wallet. To
            share crypto on Easyshare, you need a wallet.
          </p>`,
  },
  {
    title: "How long do I have to wait for a transaction to be completed?",
    content: `<p>
            As soon as the crypto is confirmed, payment will be made to the
            recipient details provided
          </p>`,
  },
  {
    title: "Have more questions?",
    content: `  <p>
            Our customer service is available 24/7 to assist you where you need
            further assistance. Send us an email at support@easyshare.africa You can
            also contact us via any of our social media channels.
          </p>`,
  },
];
