import React from 'react'
import { Link } from "react-router-dom";
import "./SidebarItem.scss"


function SidebarItem({item, location, path}) {
    let { label, link, icon, /** subitems */ } = item;
    let activelinkClass = "";
   
    if (location) {
    
      const { pathname } = location;
      
      var isLinkActive =
        link && (pathname === link || pathname.indexOf("/" + link) !== -1);
        //check if it is root path
        if (link === "" && pathname === (path + "/")) {
          isLinkActive = true;
        }
        activelinkClass = isLinkActive ? "active" :activelinkClass;
    }
    return (
      <li className={`sidebar_item ${activelinkClass}`}>
        <Link to={`${path}/${link}`} className='link'>
            <span className={`sidebar-item-text `}>
              {icon && <i className={icon}></i>}
              {label}
            </span>
        </Link>
      </li>
    );
}

SidebarItem.propTypes = {

}

export default SidebarItem

