import React from "react";
import "./Info.scss";

const unSupportedRegion = [
  "Belarus",
  "Bosnia and Herzegovina",
  "Botswana",
  "Burundi",
  "Cambodia",
  "Canada",
  "Central African Republic",
  "People's Republic of China (but not including the special administrative regions of Hong Kong and Macau, and the territory of Taiwan)",
  "Congo (Democratic Republic)",
  "Cuba",
  "Eritrea",
  "Ethiopia",
  "Ghana",
  "Iran",
  "Iraq",
  "Lebanon",
  "Liberia",
  "Libya",
  "Mali",
  "Moldova (Republic of)",
  "Montenegro",
  "Myanmar",
  "New Zealand",
  "Nicaragua",
  "Democratic People's Republic of Korea",
  "Puerto Rico",
  "Republic of Kosovo",
  "Rwanda",
  "Serbia",
  "Slovakia",
  "Somalia",
  "South Sudan",
  "Sri Lanka",
  "Sudan",
  "Syrian Arab Republic",
  "Thailand",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Uganda",
  "United States of America",
  "Venezuela",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Yemen",
  "Zimbabwe",
];

function TermandConds() {
  return (
    <section className="Info">
      <div className="InfoContent">
        <h2 className="pageTitle">Terms and Conditions</h2>

        <p className="text">
          Welcome to Easyshare.africa herein referred to as Easyshare. If you
          continue to browse and use Easyshare, you are agreeing to comply with
          and be bound by the following terms and conditions of use, which,
          together with our privacy policy, govern Easyshare’s relationship with
          you in relation to all our applications and platforms. If you disagree
          with any part of these terms and conditions, please do not use any of
          our applications and platforms.
        </p>

        <p className="text">
          The terms ‘Easyshare.africa’ or ‘us’ or ‘we’ refer to the owner/s of
          Easyshare. The use of Easyshare is subject to the following terms of
          use:{" "}
        </p>

        <p className="text">
          The content of the pages of Easyshare is for your general information
          and use only. It is subject to change without notice.
        </p>

        <p className="text">
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on Easyshare.africa
          for any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.
        </p>

        <p className="text">
          Your use of any information or materials on Easyshare is entirely at
          your own risk, for which we shall not be liable. It shall be your own
          responsibility to ensure that any products, services or information
          available through Easyshare meet your specific requirements.
        </p>

        <p className="text">
          Easyshare platforms contain material which is owned by or licensed to
          us. This material includes, but is not limited to, the design, layout,
          look, appearance and graphics. Reproduction is prohibited other than
          in accordance with the copyright notice, which forms part of these
          terms and conditions.
        </p>

        <p className="text">
          All trademarks reproduced on Easyshare, which are not the property of,
          or licensed to the operator, are acknowledged on our applications and
          platforms. Unauthorised use of Easyshare may give rise to a claim for
          damages and/or be a criminal offence.
        </p>
        <ul style={{marginBottom:20, listStylePosition:"inside"}}>
          <p className="text">
            Users from the following countries are not allowed to use the
            Easyshare.Africa platform. This prohibition applies to all types of
            people (moral, physical, agent etc.) including legal entities and to
            any indirect participation (via a proxy, a name loan etc.) By using
            the Easyshare.Africa platform, you guarantee that you are not
            accessing Easyshare from any of the following territories nor are
            you a citizen and you meet this requirement.
          </p>
          {unSupportedRegion.map((region, i) => (
            <li style={{margin:5}} className="text" key={`${region}${i}`}>{region}</li>
          ))}
        </ul>

        <p className="text">
          From time to time, Easyshare applications and platforms may also
          include links to other websites, applications and/or platforms. These
          links are provided for your convenience to provide further
          information. They do not signify that we endorse the website(s),
          application(s) and/or platform(s). We have no responsibility for the
          content of the linked website(s), application(s) and/or platform(s).
          <br />
          Easyshare reserves the right to make changes to our website,
          applications and/or platforms or any materials related to Easyshare
          without notice.
        </p>
      </div>

      <br />
      <br />
    </section>
  );
}

export default TermandConds;
