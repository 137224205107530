import { useRef, useEffect } from "react";

const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = cb.current;
    window.onbeforeunload = onUnload;
    return () => (window.onbeforeunload = null);
  }, []);
};

export default useUnload;
