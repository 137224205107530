import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { CurrencyContext } from "../_helpers";
import { format, isEmpty } from "../utils";
import * as Yup from "yup";

const requiredNumber = Yup.number()
  .typeError("invalid amount")
  .required("required");

function useCalculator({
  country = "NGN",
  minAmount = 0,
  maxAmount = Infinity,
  networkOption,
  disableMinMaxValidation = false,
  defaultValue = {
    send: 0,
    receive: 0,
  },
  defaultPairs = {
    receive: null,
    send: null,
  },
}) {
  const { currencies, isLoaded, setNetwork } = useContext(CurrencyContext);
  const [send, setSend] = useState({});
  const [receive, setReceive] = useState({});
  const [sendError, setSendError] = useState("");
  const [receiveError, setReceiveError] = useState("");
  const [touched, setTouched] = useState(false);
  const [sendValue, setSendValue] = useState(defaultValue.send || 0);
  const [receiveValue, setReceiveValue] = useState(defaultValue.receive || 0);
  const [limitError, setLimitError] = useState({ isHigh: false, isLow: false });

  useEffect(() => {
    if (networkOption) {
      setNetwork(networkOption);
    }
  }, [networkOption, setNetwork]);

  //Not sure if this useEffect will work well all the time
  useEffect(() => {
    setSendValue(defaultValue.send);
    setReceiveValue(defaultValue.receive);
  }, [defaultValue.send, defaultValue.receive]);

  const receiveRef = useRef(null);
  const sendRef = useRef(null);

  function setFieldError(point, value) {
    if (point === "send") setSendError(value);
    if (point === "receive") setReceiveError(value);
  }

  const validateField = useCallback(
    (name, value) => {
      if (limitError.isHigh || limitError.isLow) return;

      try {
        requiredNumber.validateSync(value);
        setFieldError(name, "");
      } catch (error) {
        setFieldError(name, error.message || `invalid value`);
      }
    },
    [limitError.isLow, limitError.isHigh]
  );

  const validateMinMax = useCallback(
    (value, disable = false) => {
      if (disable) return;
      //format value

      if (value < minAmount) {
        setSendError(
          `Amount should be between $${minAmount} and $${maxAmount}`
        );
        setLimitError({ isLow: true, isHigh: false });
      } else if (value > maxAmount) {
        setSendError(
          `Amount should be between $${format(minAmount)} and $${format(
            maxAmount
          )}`
        );
        setLimitError({ isLow: false, isHigh: true });
      } else {
        setSendError("");
        setLimitError({ isLow: false, isHigh: false });
      }
    },
    [minAmount, maxAmount]
  );

  useEffect(() => {
    if (currencies && receiveValue) {
      const usd = currencies.find((curr) => curr?.sendDisplayName === "USD");
      if (!usd) return;
      const ngn = usd?.receive.find((curr) => curr?.displayName === "NGN");
      let value = receiveValue / ngn?.rate || 0;

      validateMinMax(value, disableMinMaxValidation);
    }
  }, [
    sendValue,
    receiveValue,
    currencies,
    validateField,
    disableMinMaxValidation,
    validateMinMax,
  ]);

  const updateReceive = useCallback(
    (value) => {
      if (receiveRef.current === document.activeElement) {
        return;
      }

      if (receive) {
        value = safelyParse(value);
        let newVal = (value * receive.rate).toFixed(2);
        const decimals = newVal - Math.floor(newVal);
        //if the decimal point is zero(.00) just floor it
        if (decimals <= 0) {
          newVal = Math.floor(newVal);
        }
        setReceiveValue(newVal);
        validateField("receive", +newVal);
      }
    },
    [receive, validateField]
  );

  const updateSend = useCallback(
    (value) => {
      if (sendRef.current === document.activeElement) {
        return;
      }
      if (receive) {
        value = safelyParse(value);
        let newVal = (value / receive.rate).toFixed(2);
        const decimals = newVal - Math.floor(newVal);
        //if the decimal point is zero(.00) just floor it
        if (decimals <= 0) {
          newVal = Math.floor(newVal);
        }
        setSendValue(newVal);
        validateField("send", +newVal);
      }
    },
    [receive, validateField]
  );

  const convertAmt = useCallback(
    (target, Touch = true) => {
      let { name, value } = target;

      if (+value < 0) {
        value = Math.abs(value);
      }

      if (!touched && Touch) setTouched(true); //setTouched only once
      //if name is send and is not the active element

      if (name === "send") {
        setSendValue(value);
        validateField("send", value);
        return updateReceive(value);
      }

      if (name === "receive") {
        setReceiveValue(value);
        validateField("receive", value);
        return updateSend(value);
      }
    },
    [touched, updateReceive, updateSend, validateField]
  );

  const isValid = () =>
    sendValue && receiveValue && !sendError && !receiveError;

  function setInitials() {
    if (isLoaded && currencies && isEmpty(send)) {
      const pickedCurr = defaultPairs.send || currencies[0];
      setSend(pickedCurr);
      console.log(pickedCurr, country)
      //look for recieve currency that equates the users bank nationality
      const baseReceive = pickedCurr?.receive?.find(
        ({ label }) => label.toUpperCase() === country.toUpperCase()
      );
      setReceive(defaultPairs.receive || baseReceive);
    }
  }

  useEffect(() => {
    //only setSend for initial load
    setInitials();
    //eslint-disable-next-line
  }, [isLoaded]);

  useEffect(() => {
    if (receiveValue && sendValue) {
      validateField("send", sendValue);
      validateField("receive", receiveValue);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoaded && !isEmpty(send)) {
      convertAmt({ name: "send", value: sendValue }, false);
    }
    //eslint-disable-next-line
  }, [send, country, isLoaded, convertAmt]);

  function handleSendChange(newSend) {
    const baseReceive = newSend.receive.find(
      ({ label }) => label.toUpperCase() === country.toUpperCase()
    );
    setSend(newSend);
    setReceive(baseReceive);
  }

  function safelyParse(value) {
    const parsed = parseFloat(value);
    return Number.isNaN(parsed) ? 0.0 : parsed;
  }

  const getValues = () => ({
    sendAmount: safelyParse(sendValue) || 0.0,
    receiveEstimatedAmount: safelyParse(receiveValue) || 0.0,
    sendCurrency: send?.value,
    receiveCurrency: receive?.value,
  });

  return {
    send,
    receive,
    isValid,
    errors: {
      receive: receiveError,
      send: sendError,
      limitError,
    },
    touched,
    refs: {
      receiveRef,
      sendRef,
      values: {
        send: sendValue,
        receive: receiveValue,
      },
      handleChange: convertAmt,
      getValues,
    },
    currencies,
    isLoaded,
    setSend: handleSendChange,
    setReceive,
  };
}

export default useCalculator;
