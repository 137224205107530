/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { string as yupString } from "yup";
import { Header, NavLogo, Spinner } from "../../../components";
//import { history } from "../../../_helpers";
import { useUserAction } from "../Factory";
import checkPasswordStrength from "../passwordGauge";
// import PropTypes from "prop-types";
import "./SignUp.scss";

const validationSchema = {
  name: yupString()
    .required()
    .required("username is required")
    .matches(/^[a-zA-Z0-9_]*$/gm, "only alphabet, numbers and underscore allowed"),

  email: yupString()
    .trim()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "invalid email format"
    )
    .required("email is required"),
  password: yupString().required("password is required"),
};

function SignUp(props) {
  const [regDetails, setRegDetails] = useState({
    name: {
      value: "",
      touched: "",
      error: "",
    },
    email: {
      value: "",
      touched: "",
      error: "",
    },
    password: {
      value: "",
      touched: "",
      error: "",
    },
    confirmPassword: {
      value: "",
      touched: "",
      error: "",
    },
  });

  const history = useHistory();

  const userAction = useUserAction();

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const [show, setShow] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);
  

  const [gauge, setGauge] = useState('');

  const { name, email, password, confirmPassword } = regDetails;

  const onChange = (e) => {
    const { value, name } = e.target;
    const { value: val, error } = validate(name, value);
    setRegDetails(() => ({
      ...regDetails,
      [name]: { value: val.trim(), error, touched: true },
    }));
  };

  const togglePassword = (e) => setShow(() => !show);

  function validate(name, value) {
    let error = "";
    try {
      if (name === "confirmPassword") {
        if (value !== password.value) {
          error = "passwords do not match!";
        } else {
          error = "";
        }
      } else {
        value = validationSchema[name]?.validateSync(value);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        error = err.errors.join(",");
      }
    }
    return { value, error };
  }
  // check the strength of the password
  const runGauge = () => {
   let gaugeValue = checkPasswordStrength(password.value)
   setGauge(gaugeValue)
  }


  // make the submit button appear disabled if there are any errors with the input field
  function isValid() {
    const { name, email, confirmPassword } = regDetails;
    return !(name.error || email.error || confirmPassword.error) && gauge === 'strong';
  }


  function validateAll(){
    let iserror = false;
     for(let key in regDetails ){
         let { value  } = regDetails[key];
        const { value: val, error } = validate(key, value);
         if(!iserror && error) iserror =true;
        setRegDetails(() => ({
          ...regDetails,
          [key]: { value: val.trim(), error, touched: true },
        }));
     }
     return iserror;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if(validateAll()) return;
    const {
      name: { value: name },
      email: { value: email },
      password: { value: password },
      confirmPassword: { value: confirmPassword },
    } = regDetails;

    if (gauge !== 'strong') {
      setShowTooltip(true)
    }

   

    setLoading(true);
    setServerError("");
    userAction
      .register({ name, email, password, confirmPassword })
      .then((status) => {
        setLoading(false);
        history.push("/affiliate/login");
      })
      .catch((err) => {
        setLoading(false);
        setServerError(Array.isArray(err) ? err.join(",") : err);
      });
  }
  return (
    <div className='Affiliate_signUp'>
      <NavLogo isCenter />
      <div className='MultiStep'>
        <Header />

        <section className='formWrap'>
          <h3 className='formTitle'>SIGN UP</h3>

          {" "}
          {serverError && (
            <span className='error' style={{ textAlign: "center", display: "block" }}>
              {serverError}
            </span>
          )}

          <div className='form-row'>
            <label htmlFor='name'>
              Username{" "}
              {name.error && (
                <span className='error'>({name.error})</span>
              )}
            </label>
            <input
              type='text'
              name='name'
              id='name'
              placeholder='Username'
              required
              value={name.value}
              onChange={onChange}
            />
          </div>
          <div className='form-row'>
            <label htmlFor='email'>
              Email{" "}
              {email.error && (
                <span className='error'>({email.error})</span>
              )}
            </label>
            <input
              type='email'
              name='email'
              id='email'
              placeholder='email'
              required
              value={email.value}
              onChange={onChange}
            />
          </div>
          <div className='form-row'>
            <label htmlFor='password'>Password</label>
            <input
              type={`${show ? "text" : "password"}`}
              name='password'
              id='password'
              placeholder='password'
              required
              value={password.value}
              onChange={onChange}
              onKeyUp={runGauge}
              onFocus={() => setShowTooltip(true)}
              onBlur={() => setShowTooltip(false)}
            />
            <i
              className={`togglePassword far ${show ? "fa-eye-slash" : "fa-eye"}`}
              onClick={togglePassword}></i>
          </div>
          {showTooltip && <div 
          className="tooltip"
          id={gauge === 'strong' ? 'success' : ''}
          >
            <i className="fas fa-info-circle"></i>
            <span>Password (at least 8 characters, containing a lowercase, uppercase, number and a special character)</span>
          </div>}
          <div className='passwordStrength'>
            <progress
              className={
                gauge === 'weak'
                  ? "weak"
                  : gauge === 'fair'
                    ? "fair"
                    : gauge === 'good'
                      ? "good"
                      : gauge === 'strong'
                        ? "strong"
                        : ""
              }
              value={
                password.value === ''
                 ? "0"
                  : gauge === 'weak'
                   ? "10"
                    : gauge === 'fair'
                     ? "40"
                      : gauge === 'good'
                       ? "65"
                        : gauge === 'strong'
                         ? "100"
                          : "0"
              }
              max='100'></progress>
            <p>
              {password.value === ''
                ? "Input password"
                : gauge === 'weak'
                  ? "Weak"
                  : gauge === 'fair'
                    ? "Fair"
                    : gauge === 'good'
                      ? "Good"
                      : gauge === 'strong'
                        ? "Strong"
                        : "Input password"}
            </p>
          </div>
          <div className='form-row'>
            <label htmlFor='password'>
              Confirm Password{" "}
              {confirmPassword.error && (
                <span className='error'>({confirmPassword.error})</span>
              )}
            </label>
            <input
              type={`${show ? "text" : "password"}`}
              name='confirmPassword'
              id='confirmPassword'
              placeholder='confirm password'
              required
              value={confirmPassword.value}
              onChange={onChange}
            />
            <i
              className={`togglePassword far ${show ? "fa-eye-slash" : "fa-eye"}`}
              onClick={togglePassword}></i>
          </div>
          <button
            onClick={handleSubmit}
            type='submit'
            id={!isValid() ? 'disabled' : ''}
            className='submit'
          >
            {loading && <Spinner light />} Sign Up
          </button>
          <p className='Alt'>
            Already have an account? <span className='link'
              onClick={()=>history.push('/affiliate/login')}
            >Log in</span>
          </p>
        </section>
      </div>
    </div>
  );
}

SignUp.propTypes = {};

export default SignUp;
