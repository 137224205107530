import React from 'react';
import "./Header.scss";


function index ( { isRequest } )
{

    return (
      <header>
        {isRequest ? (
          <p>Request money from anyone anywhere</p>
        ) : (
          <p>
            Enjoy the freedom of sharing <br /> using crypto
          </p>
        )}
      </header>
    );


}

export default index;
