function logout ()
{
  // remove user from local storage to log user out
  localStorage.removeItem( "auth" );
}

export const handleResponse = ( response, preventReloadOnAuthorizationError = false ) =>
{
  return response
    .text()
    .then( ( text ) =>
    {
      const data = text && JSON.parse( text );
      //  console.log(response);
      if ( !response.ok )
      {
        if ( response.status === 401 )
        {
          // auto logout if 401 response returned from api
          logout();
          if ( !preventReloadOnAuthorizationError )
          {
            window.location.reload();
          }
        }
        const error = data && data.message ? data.message : "Error Occurred";
        return Promise.reject( error );
      }
      return data;
    } )
    .catch( ( error ) =>
    {
      console.log( error );
      return Promise.reject( error );
    } );
};

export const BaseUrl = process.env.REACT_APP_BACKEND_AFFILIATE_URL + "/v1";
