import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
// import PropTypes from "prop-types";
import "./Form.scss";
import OTP from "./Otp";
import { BackBtn, Spinner } from "../index";
import { makeReq } from "../../utils";
import { useUnload } from "../../hooks";
import { ReactComponent as Info } from "../../assets/images/info.svg";
import ReactTooltip from "react-tooltip";

const contactSchema = Yup.object().shape({
  customerInfo: Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(/^[aA-zZ\s]+$/, "name is not valid")
      .required("name is required"),
    email: Yup.string()
      .trim()
      .email("must be a valid email")
      .required("email is required"),
  }),
  acceptedTermsAndCondition: Yup.bool()
    .oneOf([true])
    .required("you mmust agree to terms and conditions"),
  isSubscribed: Yup.bool(),
});

function ContactDetails({
  changePage,
  page,
  setFormData,
  setOrder,
  back,
  data,
}) {
  const [isInVerify, setIsInVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState(null);

  const goNext = () => {
    changePage(page + 1, true);
  };

  const goBack = (e) => {
    e.stopPropagation();
    changePage(page - 1, true);
  };

  const {
    customerInfo,
    isSubscribed,
    acceptedTermsAndCondition,
    transactionType,
  } = data.transactionalState;

  useUnload((e) => {
    e.preventDefault();
    const message = "your changes might be lost";
    e.returnValue = message;
    return message;
  });

  // this prop has been deprecated in formik
  // function isInitialValid(props) {
  //   if (!props.validationSchema) return true;
  //   return props.validationSchema.isValidSync(props.initialValues);
  // }

  function initiateTransaction(values, isResend) {
    const formValue = isResend
      ? data.transactionalState
      : { ...data.transactionalState, ...values };

    setLoading(true);
    const { customerInfo } = formValue;
    const req = {
      email: customerInfo.email,
      name: customerInfo.name,
      type: transactionType.toLowerCase()
    };

    makeReq(`cart/verify/begin`, {
      method: "POST",
      body: JSON.stringify(req),
    })
      .then((cart) => {
        setCart(cart?.data);
        setIsInVerify(true);
        setLoading(false);
      })
      .catch((err) => {
        alert(err.message || err);
        setLoading(false);
      });
  }

  return isInVerify ? (
    <OTP
      onResend={() => initiateTransaction(null, true)}
      onVerified={(order) => {
        const formValue = data.transactionalState;
        const { accountDetails, customerInfo } = formValue;
        console.log(accountDetails);
        switch (transactionType.toLowerCase()) {
          case 'share':
            console.log(formValue);
            const req = {
              recipient: {
                accountDetails,
                email: customerInfo.email,
                name: customerInfo.name,
                country: 'NG',
              },
              id: order.requestId,
              sendCurrency: formValue.sendCurrency,
              sendAmount: formValue.sendAmount,
              fee: 0,
              cryptoCurrency: formValue.payWithCrypto,
              receiveCurrency: formValue.receiveCurrency,
            };
            makeReq(`cart/${transactionType.toLowerCase()}`, {
              method: "POST",
              body: JSON.stringify(req),
            })
              .then((cart) => {
                order.requestId = cart.data.uniqueId;
                const shareCartDto = cart
                setIsInVerify(true);
                setLoading(false);
                setOrder(shareCartDto);
                goNext();
              })
              .catch((err) => {
                alert(err.message || err);
                setLoading(false);
              });
            break;
          case 'request': 
            const request = {
              accountDetails: {
                accountNumber: accountDetails.accountNumber,
                accountName: accountDetails.accountName,
                accountNumberType: accountDetails.accountNumberType,
                bankName: accountDetails.bankName,
                bankCode: accountDetails.bankCode
              },
              id: order.requestId
            };
            makeReq(`cart/${transactionType.toLowerCase()}`, {
              method: "POST",
              body: JSON.stringify(request),
            })
              .then((cart) => {
                order.requestId = cart.data.uniqueId;
                setIsInVerify(true);
                setLoading(false);
                setOrder(order);
                goNext();
              })
              .catch((err) => {
                alert(err.message || err);
                setLoading(false);
              });
          default:
            break;
        }
      }}
      back={() => setIsInVerify(false)}
      txRef={cart?.requestId}
      email={customerInfo.email}
      customerInfo={customerInfo}
      type={transactionType.toLowerCase()}
      resendLoading={loading}
    />
  ) : (
    <Formik
      initialValues={{
        customerInfo,
        isSubscribed,
        acceptedTermsAndCondition,
        transactionType,
      }}
      validateOnMount
      enableReinitialize={true}
      validationSchema={contactSchema}
      // isInitialValid={isInitialValid}
      onSubmit={(values) => {
        setFormData(values);
        initiateTransaction(values);
      }}>
      {({ errors, touched, isValid, dirty, values }) => (
        <Form>
          <BackBtn to={goBack} />
          <div>
            <p className='formMessage'>
              Let’s get you notification ready <br />
              We’ll keep you notified on the progress
              <br /> of your transaction
            </p>
            <div className='form-row'>
              <label htmlFor='customerInfo.name'>
                Enter your name{" "}
                <span
                  className='info'
                  data-tip='This will be added as a remark <br /> to the recipent'
                  data-background-color='#d3efea'
                  data-multiline={true}
                  data-text-color='#303030'>
                  <Info />
                </span>{" "}
                <span className='error'>
                  {errors.customerInfo &&
                    errors.customerInfo.name &&
                    touched.customerInfo &&
                    touched.customerInfo.name ? (
                    <small>({errors.customerInfo.name})</small>
                  ) : null}
                </span>
              </label>
              <Field
                type='text'
                name='customerInfo.name'
                placeholder='e.g Jane Doe'
                required='required'
              />
            </div>
            <div className='form-row'>
              <label htmlFor='customerInfo.email'>
                Enter your email address{" "}
                <span className='error'>
                  {errors.customerInfo &&
                    errors.customerInfo.email &&
                    touched.customerInfo &&
                    touched.customerInfo.email ? (
                    <small>({errors.customerInfo.email})</small>
                  ) : null}
                </span>{" "}
              </label>
              <Field
                type='email'
                name='customerInfo.email'
                placeholder='e.g Janedoe@yahoo.com'
                required='required'
              />
            </div>
            <div className='form-row-checkbox'>
              <Field
                type='checkbox'
                name='acceptedTermsAndCondition'
                id='tandc'
              />
              <label htmlFor='tandc'>
                I have read Easyshare’s{" "}&nbsp;
                <Link to='/terms' target='_blank'>
                  Terms of Service
                </Link>
              </label>
            </div>
            <div className='form-row-checkbox'>
              <Field type='checkbox' name='isSubscribed' id='isSubscribed' />
              <label htmlFor='isSubscribed'>
                You can occasionally email me updates about <br /> Easyshare
                (OPTIONAL)
              </label>
            </div>
            <button
              className='submit'
              type='submit'
              disabled={loading || !isValid}>
              {loading ? <Spinner light /> : "Send OTP"}
            </button>
          </div>
          <ReactTooltip />
        </Form>
      )}
    </Formik>
  );
}

ContactDetails.propTypes = {};

export default ContactDetails;
