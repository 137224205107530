import React, { useState, useEffect, createContext } from "react";
import { baseUrl, sortByKeyAsc } from "../utils";

const CoinContext = createContext();

function CoinProvider({ children }) {
  const [coins, setCoins] = useState([]);
   const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      

      try {
        setLoading(true);
        // let coins = await fetch(`${baseUrl}/rates/exchange/crypto`);
        let testCoins = await fetch(`${baseUrl}/send/available-crypto-networks`)
        if (testCoins.ok) {
          // coins = await coins.json();
          testCoins = await testCoins.json()
          //sort incoming object
          // coins = sortByKeyAsc(coins,"crypto");
          // coins = coins.map((c) => ({
          //   ...c,
          //   coin: c.crypto,
          //   label:c.cryptoDisplayName,
          //   value:c.crypto,
          //   rate: +0,

          // }));
          const mappedCoins = testCoins.map((c) => ({
            ...c,
            coin: c.name,
            label: c.displayName,
            value: c.name,
            rate: +0,
            cryptoDisplayName: c.displayName
          }));
          setError(null);
          setCoins(mappedCoins);
          setLoading(false);
        } else {
          setError("Unable to retrieve coins");
        }
      } catch (error) {
        console.log(error);
        setError(error.mesage);
      }
    };

    getData();

    //update every 10 secs
    // since we are only fetching once
    // const timer = setInterval(getData, 10 * 1000);

    // return () => clearInterval(timer);
  }, []);

  return (
    <CoinContext.Provider value={{ error, coins, loading }}>
      {children}
    </CoinContext.Provider>
  );
}

export { CoinProvider, CoinContext };
