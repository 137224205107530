import { buildQueryString } from "../../../../utils";
import { authHeader } from "../../../../_helpers";
import { BaseUrl, handleResponse } from "../_globService";

export const UserService = {
  login,
  logOut,
  register,
  resetUserPassword,
  getUserProfile,
  getUserRefferalCommission,
  getUserTransactions,
  getTopPerformingReferral,
  getUserReferrals,
  forgotPassword,
  validateResetToken,
  createNewPassword,
  addOrUpdateBankInfo,
  uploadFileTOSW3,
  UpdateProfileInfo,
  resendConfirmationMail,
  confirmUser,
};

async function login(loginDto) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginDto),
  };

  const response = await fetch(`${BaseUrl}/auth/login`, requestOptions);
  return handleResponse(response, true);
}

async function register(userDto) {
  const requestOptions = {
    method: "POST",
    accept: "*/*",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userDto),
  };

  const response = await fetch(`${BaseUrl}/auth/signup`, requestOptions);
  return handleResponse(response);
}

async function forgotPassword(params) {
  const requestOptions = {
    method: "POST",
    accept: "*/*",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  };

  const response = await fetch(
    `${BaseUrl}/auth/forgetpasswordbegin`,
    requestOptions
  );
  return handleResponse(response);
}

async function validateResetToken(token) {
  const requestOptions = {
    method: "GET",
    accept: "*/*",
    headers: { "Content-Type": "application/json" },
  };

  const response = await fetch(
    `${BaseUrl}/auth/user/loadforgetpassword?token=${token}`,
    requestOptions
  );
  return handleResponse(response);
}

async function createNewPassword(params) {
  const requestOptions = {
    method: "POST",
    accept: "*/*",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  };

  const response = await fetch(
    `${BaseUrl}/auth/user/completeForgetPassword`,
    requestOptions
  );
  return handleResponse(response);
}

const defaultQueryObj = { sortby: "name", page: 1, size: 25, dir: "ASC" };

async function resetUserPassword(resetPasswordDto) {
  const requestOptions = {
    method: "POST",
    accept: "*/*",
    headers: authHeader(),
    body: JSON.stringify(resetPasswordDto),
  };

  let response = await fetch(`${BaseUrl}/auth/user/reset`, requestOptions);
  return handleResponse(response);
}

async function getUserProfile() {
  const requestOptions = {
    method: "GET",
    accept: "*/*",
    headers: authHeader(),
  };

  const response = await fetch(`${BaseUrl}/user/profile`, requestOptions);
  return handleResponse(response);
}

async function getUserRefferalCommission() {
  const requestOptions = {
    method: "GET",
    accept: "*/*",
    headers: authHeader(),
  };

  const response = await fetch(
    `${BaseUrl}/user/referralCommission`,
    requestOptions
  );
  return handleResponse(response);
}

async function getUserTransactions(
  queryObj = { sortby: "name", page: 1, size: 25, dir: "ASC" }
) {
  const queryString = buildQueryString(queryObj);
  const requestOptions = {
    method: "GET",
    accept: "*/*",
    headers: authHeader(),
  };

  const response = await fetch(
    `${BaseUrl}/user/transactions?${queryString || ""}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getUserReferrals(queryObj) {
  const queryString = buildQueryString({ ...defaultQueryObj, ...queryObj });
  const requestOptions = {
    method: "GET",
    accept: "*/*",
    headers: authHeader(),
  };

  const response = await fetch(
    `${BaseUrl}/user/referrals?${queryString || ""}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getTopPerformingReferral() {
  const requestOptions = {
    method: "GET",
    accept: "*/*",
    headers: authHeader(),
  };

  const response = await fetch(`${BaseUrl}/user/topreferrals`, requestOptions);

  return handleResponse(response);
}

async function addOrUpdateBankInfo(bankDetails) {
  const requestOptions = {
    method: "PUT",
    accept: "*/*",
    headers: authHeader(),
    body: JSON.stringify(bankDetails),
  };

  const response = await fetch(`${BaseUrl}/user/bank`, requestOptions);

  return handleResponse(response);
}

async function UpdateProfileInfo(info) {
  const requestOptions = {
    method: "PUT",
    accept: "*/*",
    headers: authHeader(),
    body: JSON.stringify(info),
  };

  const response = await fetch(`${BaseUrl}/user/profile`, requestOptions);
  return handleResponse(response);
}

async function uploadFileTOSW3(file) {
  //  const data = new FormData()
  //  data.append("file", file, file?.name);

  const data = { image64: file };

  const requestOptions = {
    method: "POST",
    accept: "*/*",
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/s3/uploadContentBase64`,
    requestOptions
  );

  return handleResponse(response);
}

async function resendConfirmationMail(callbackUrl) {
  const requestOptions = {
    method: "POST",
    accept: "*/*",
    body: JSON.stringify({ callbackUrl }),
    headers: authHeader(),
  };

  const response = await fetch(
    `${BaseUrl}/auth/createConfirmation`,
    requestOptions
  );
  return handleResponse(response, true);
}

async function confirmUser(token) {
  const requestOptions = {
    method: "GET",
    accept: "*/*",
  };

  const response = await fetch(
    `${BaseUrl}/auth/verify/${token}`,
    requestOptions
  );
  return handleResponse(response, true);
}

// no service to get all users
function logOut() {}
