import React from 'react'
import "./Network.scss"

// import PropTypes from 'prop-types'

function NetworkList({ data, onChange, selected }) {
    return (
      <div className='network__list'>
        <h4>Select your preferred network</h4>
        <div className={`network__list__item head`}>
          <p>Network</p>
          <p className='right_p'>Minimum amout</p>
        </div>
        {data?.length
          ? data.map((item) => (
              <NetworkItem
                networkName={item?.tokenName}
                minAmountUsd={item?.minAmountUSD}
                key={item?.tokenName}
                isSelected={item?.tokenName === selected?.tokenName}
                onChange={() => onChange(item)}
              />
            ))
          : null}
      </div>
    );
    
}

function NetworkItem({ isSelected, onChange, networkName, minAmountUsd }){
      let selectedClassName = isSelected ? "selected" : "";
      return (
        <div
          className={`network__list__item ${selectedClassName}`}
          onClick={onChange}>
          <p>{networkName}</p>
          <p className='right_p'>${minAmountUsd}</p>
        </div>
      );
}

NetworkList.defaultProps = {
  onChange:()=>{}
}

export default NetworkList

