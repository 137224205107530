import { userConstants } from "./User.constants";
import { UserService } from "./User.service";

export default class UserAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async login(loginDto) {
    try {
      const response = await UserService.login(loginDto);
      this.handleLogIn(response);
      this.loadAll();
      return "success";
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async register(createUserDto) {
    try {
      const response = await UserService.register(createUserDto);
      //   this.handleLogIn(response);

      return response;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async getUserProfile() {
    try {
      const profile = await UserService.getUserProfile();
      this.dispatch({ type: userConstants.PROFILE_SUCCESS, profile });
      localStorage.setItem("user", JSON.stringify(profile));
      return profile;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async getUserRefferalCommission() {
    try {
      const commissions = await UserService.getUserRefferalCommission();
      this.dispatch({ type: userConstants.COMMISSION_SUCCESS, commissions });
      return commissions;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async getUserTransactions(
    queryObj = { page: 1, sortby: "createdAt", size: 25, dir: "ASC" }
  ) {
    try {
      const transactions = await UserService.getUserTransactions(queryObj);
      this.dispatch({ type: userConstants.TRANSACTION_SUCCESS, transactions });
      return transactions;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async getUserReferrals(queryObj) {
    try {
      const referrals = await UserService.getUserReferrals(queryObj);
      this.dispatch({ type: userConstants.REFERRALS_SUCCESS, referrals });
      // console.log( referrals );
      return referrals;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async getTopPerformingReferral() {
    try {
      const topReferral = await UserService.getTopPerformingReferral();
      //success should be maintained at componenet level
      // this.dispatch({ type: userConstants.TOP_REFERAL_SUCCESS, topReferral });
      return topReferral;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async loadAll(shouldThrowError = false) {
    this.dispatch({ type: userConstants.REFRESH_REQUEST });
    //will improve on this to return appropriate error
    const all = await Promise.allSettled([
      this.getTopPerformingReferral(),
      this.getUserRefferalCommission(),
      this.getUserTransactions(),
      this.getUserProfile(),
      this.getUserReferrals(),
    ]);

    this.dispatch({ type: userConstants.REFRESH_SUCCESS });
    const failed = all.filter((res) => res.status === "rejected");
    if (failed?.length > 0 && shouldThrowError) {
      return Promise.reject(
        "could not fetch your data please check your network or refresh page "
      );
    }
    return all;
  }

  handleLogIn(auth) {
    localStorage.setItem("auth", JSON.stringify(auth));
    this.dispatch({
      type: userConstants.LOGIN_SUCCESS,
      auth,
    });
  }

  handleLogOut() {
    localStorage.removeItem("auth");
    this.dispatch({
      type: userConstants.LOGOUT_SUCCESS,
    });
  }

  async forgotPassword(params) {
    try {
      const response = await UserService.forgotPassword(params);

      return response;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async validateResetToken(token) {
    try {
      const response = await UserService.validateResetToken(token);

      return response;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async createNewPassword(params) {
    try {
      const response = await UserService.createNewPassword(params);

      return response;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async addOrUpdateBankDetails(bankDetails) {
    try {
      const profile = await UserService.addOrUpdateBankInfo(bankDetails);
      this.dispatch({ type: userConstants.PROFILE_SUCCESS, profile });
      localStorage.setItem("user", JSON.stringify(profile));
      return profile?.bankDetails;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async updateProfileImage(file) {
    try {
      const profileUrl = await UserService.uploadFileTOSW3(file);
      const newProfile = await UserService.UpdateProfileInfo({
        profileImage: profileUrl,
      });
      //console.log( newProfile );
      return newProfile;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }

  async UpdateProfileInfo(profileInfo) {
    try {
      const profile = await UserService.UpdateProfileInfo(profileInfo);
      this.dispatch({ type: userConstants.PROFILE_SUCCESS, profile });
      return profile;
    } catch (err) {
      return Promise.reject(err?.message || err);
    }
  }
}
