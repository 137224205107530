import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form, useFormikContext } from "formik";
import { Spinner, BackBtn } from "../index";
import { baseUrl } from "../../utils";
import { useUnload } from "../../hooks";
import "./Form.scss";

const accountSchema = Yup.object().shape( {
  accountName: Yup.string().required( "account name is required" ),
  country: Yup.string().required( "Country name is required" ),
  bankCode: Yup.string().required( "Bank name is required" ),
  accountNumber: Yup.string().required( "Account number is required" ),
} );

const init = {
  loading: false,
  error: null,
};

const AutoSetName = ( { name } ) =>
{
  const { setFieldValue } = useFormikContext();
  React.useEffect( () =>
  {
    setFieldValue( "accountName", name );
  }, [ name, setFieldValue ] );
  return null;
};

function BankDetails ( { changePage, page, back, data, setField, setBanks, text } )
{
  const { accountDetails: account } = data.transactionalState;
  let { banks, countries } = data.core;
  const [ state, setState ] = useState( init );
  const [ banksLoading, setBSL ] = useState( false );
 
  const submitForm = async ( values ) =>
  {
    setState( {
      loading: true,
      error: null,
    } );

    try
    {
      // const resp = await fetch(
      //   `${ baseUrl }/utils/bank/verifyAccount?accountNumber=${ values.accountNumber }&bankCode=${ values.bankCode }`
      // );

      const response = await fetch(
        `${baseUrl}/bank/resolve/${values.accountNumber}/${values.bankCode}`
      );

      // console.log(await response.text());

      const json = await response.text();

      if (response.ok )
      {
        setState( {
          loading: false,
          error: null,
        } );

        setField( "accountName", json );

        return;
      }

      throw new Error('Could not resolve this account number, please check the details ');
    } catch ( error )
    {
      console.log(error);
      setState( {
        loading: false,
        error: error.message,
        user: null,
      } );
    }
  };

  const getBanks = useCallback( async ( value ) =>
  {
    setState( init );
    setBSL( true );

    if ( value && value !== account.country )
    {
      try
      {
        // const resp = await fetch( `${ baseUrl }/utils/banks/${ value }` );
        const encodedValue = encodeURIComponent(value)
        // const response = await fetch(`https://api-dev.easyshare.africa/bank/${encodedValue}/banks`);
        const response = await fetch(`${baseUrl}/bank/${value}/banks`);

        if (response.ok )
        {
          const json = await response.json();
          setBSL( false );
          const banks = json.map( ( { name, code, id } ) => ( {
            name,
            id,
            code,
          } ) );
          setBanks( banks );
          return;
        }
      } catch ( error )
      {
        setBSL( false );
        console.log(error);
        alert( "Unable to fetch banks. Please check your connection" );
      }
    }
  }, [ account.country, setBanks ] );


  const changeHandler = ( e, formikHandler ) =>
  {
    formikHandler( e );
    const { name, value } = e.target;
    setField( name, value );
    if ( name === "country" && value )
    {
      getBanks( "Nigeria" );
    }
  };

  const selectBank = ( e ) =>
  {
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[ index ];
    const option = optionElement.getAttribute( "data-name" );
    setField( "bankName", option );
  };


  const goNext = ( e ) =>
  {
    e.stopPropagation();
    changePage( page + 1, true );
  };

  useEffect( () =>
  {
    // if ( !banks.length )
    // {
    //   getBanks( "ngn" );
    setBanks([])
    // }
  }, [ ] );


  useUnload( ( e ) =>
  {
    e.preventDefault();
    const message = "your changes might be lost";
    e.returnValue = message;
    return message;
  } );

 useEffect(()=>{
  setField("accountNumberType", "BANK");
 },[setField])
 
  return (
    <Formik
      initialValues={account}
      validationSchema={accountSchema}
      onSubmit={submitForm}>
      {({
        errors,
        touched,
        values,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form autoComplete='off'>
          <BackBtn to={back} />
          <p className='formMessage'>
           {text}
            <br />
            below
          </p>
          <div className='form-row'>
            <label htmlFor='country'>
              Country{" "}
              {errors.country && touched.country ? (
                <small>({errors.country})</small>
              ) : null}
            </label>
            <select
              name='country'
              id='country'
              value={account.country}
              onBlur={handleBlur}
              onChange={(e) => {
                changeHandler(e, handleChange);
              }}>
              <option value='' style={{ color: "rgba(48, 48, 48, 0.5)" }}>
                Select country{" "}
              </option>
              {countries.map((d, index) => (
                <option id={d} key={index} value={d.short}>
                  {d.country}
                </option>
              ))}
            </select>
          </div>
          <div className='form-row'>
            <label htmlFor='bankCode'>
              Bank Name{" "}
              {errors.bankCode && touched.bankCode ? (
                <small>({errors.bankCode})</small>
              ) : null}
            </label>
            <select
              name='bankCode'
              id='bankCode'
              onBlur={handleBlur}
              disabled={!banks.length}
              value={account?.bankCode}
              onChange={(e) => {
                changeHandler(e, handleChange);
                selectBank(e);
              }}>
              <option data-name='' value=''>
                Select bank
              </option>
              {banks.map((d, i) => (
                <option data-name={d.name} key={d.id} value={d.code}>
                  {d.name}
                </option>
              ))}
            </select>

            <div style={{ minHeight: 10, marginTop: 5 }}>
              {banksLoading ? (
                <>
                  <Spinner />
                  &nbsp;&nbsp;&nbsp; <span> Loading banks</span>
                </>
              ) : null}
            </div>
          </div>
          <div className='form-row'>
            <label htmlFor='accountNumber'>Account Number</label>
            <input
              type='tel'
              name='accountNumber'
              id='accountNumber'
              onBlur={handleBlur}
              placeholder='e.g 2020202020'
              value={account?.accountNumber}
              onInput={(e) => {
                if (e.target.value.length === 10) {
                  //value was not being updated on time
                  //request was being sent with only 9chars
                  submitForm({ ...values, accountNumber: e.target.value });
                }
              }}
              onChange={(e) => {
                changeHandler(e, handleChange);
              }}
            />
            <div className='form_state'>
              {errors.accountNumber && touched.accountNumber ? (
                <small>{errors.accountNumber}</small>
              ) : null}

              {state.error ? (
                <p className='error'>! &nbsp; {state.error}</p>
              ) : null}

              {account?.accountName ? (
                <p className='user'>{account?.accountName}</p>
              ) : null}

              {state.loading && !state.error ? (
                <div>
                  <span>
                    <Spinner />
                  </span>
                  &nbsp;&nbsp;&nbsp; <span> Verifying</span>{" "}
                </div>
              ) : null}
            </div>
          </div>
          <AutoSetName name={account?.accountName} />
          <button
            style={{ marginTop: 20 }}
            disabled={!isValid}
            className='submit'
            type='button'
            onClick={goNext}>
            Continue
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default BankDetails;
