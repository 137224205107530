import React, { useEffect, useState } from 'react';
import { usePage } from "../../hooks";
import { MultiStep, CopyLink, ContactDetails } from "../../components";
import ChooseRecieveMode from "../../components/FormPages/ChooseRecieveMode";
import "./Share.scss";
import
  {
    useTransactionState,
    useUiState,
    useTransactionAction,
  } from "../../_helpers";
function Receive ()
{
  const { page, changePage, verifiedPages, clearVp } = usePage( 4 );
  const [ order, setOrder ] = useState( null );
  const tState = useTransactionState();
  const Taction = useTransactionAction();
  const { UiState } = useUiState();

  useEffect( () =>
  {
    if ( !tState.transactionType || tState.transactionType !== "REQUEST" )
    {
      Taction.setTransactionType( "REQUEST" );
    }
  }, [ Taction, tState.transactionType ] );

  const globalProps = {
    page,
    changePage,
    data: { transactionalState: tState, core: UiState, order },
  };

  const text = {
    operator: "Please enter your OPay phone number",
    bank: "Please enter your account details below",
  };
   const modeText = {
     primary: "I would like to receive money in my",
     main: `First, let’s get started with your <br /> details`,
   };
  const pages = [
    <ChooseRecieveMode
      modeText={ modeText }
      text={text}
      {...globalProps}
    />,
    <ContactDetails
      {...globalProps}
      setFormData={Taction.setCustomerDetails}
      setOrder={setOrder}
    />,
    <CopyLink {...globalProps} />,
  ];

  
 useEffect(() => {
   if (page === pages.length - 1) {
     clearVp();
   }
 }, [page, pages.length, clearVp]);
  return (
    <div className='Share'>
      <MultiStep
        pages={pages}
        page={page}
        isRequest
        changePage={changePage}
        verifiedPages={verifiedPages}
        title='EASYSHARE REQUEST'
      />
    </div>
  );
}

export default Receive;
