import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

import
{
  Normal,
  HomeCalc,
  Lock,
  Percent,
  Signin,
  SendFrame,
  ReceiveFrame,
  ListIcon,
  Testimonial,
  BgColors,
  //IMAGES EXPORTED FROM ICON
  tokenLogo,
} from "../../components";

import "./Home.scss";
import queryString from "query-string";
import { sendSteps, receiveSteps, testimonials, pressList } from "./data";
import
{
  useTransactionState,
  useTransactionAction,
  useUiState,
} from "../../_helpers";
import { listToMatrix } from "../../utils";

function Home ()
{
  const { search } = useLocation();
  const history = useHistory();
  const tState = useTransactionState();
  const Taction = useTransactionAction();
  const { UiActions } = useUiState();
  const queryParams = queryString.parse( window.location.search );
  const rId = queryParams.rId;
  const affiliateId = queryParams.affiliateId;

  useEffect( () =>
  {
    ReactPixel.pageView();
  }, [] );

  useEffect( () =>
  {
    if ( tState.transactionType )
    {
      Taction.setTransactionType( "" );
      UiActions.setRM( 0 );
    }
   
  }, [ Taction, tState.transactionType, UiActions ] );

  useEffect( () =>
  {
    if ( !search )
    {
      history.push( "/" );
    }
  }, [ search, history ] );

  return (
    <div className='Home'>

      <div id="Home">
        <section className='hero'>
          <Normal rId={ rId } affiliateId={ affiliateId } />
        </section>

        <section className='conveter'>
          <div className='pagewrap'>
            <HomeCalc isFromRequest={ rId } />
          </div>
        </section>
      </div>

      {/* so that we don't have clashing navbar highlighting */ }
      <br />


      <section id='Why' className='assurance'>
        <div className='pagewrap'>
          <h2 className='title'>Why choose us</h2>
          <div className='assurance__box'>
            <div className='assurance__box__item'>
              <Lock />
              <h3>Secured via blockchain</h3>
            </div>
            <div className='assurance__box__item'>
              <Percent />
              <h3>Great rates</h3>
            </div>
            <div className='assurance__box__item'>
              <Signin />
              <h3>No login required</h3>
            </div>
          </div>
        </div>
      </section>

      {/* so that we don't have clashing navbar highlighting */ }
      <br />
      <br />

      <section id='How' className='steps'>
        <div className='pagewrap'>
          <h3 className='title'>How It Works</h3>
          <div className='steps_info send'>
            <div className='frame'>
              <SendFrame />
            </div>
            <div className='list'>
              <ListIcon
                data={ sendSteps }
                title='How To Send Money Using Crypto'
                allowHtml
              />
              <a href='/send' className='btn-green'>
                Send Money
              </a>
            </div>
          </div>
          <div className='steps_info inverse'>
            <div className='list'>
              <ListIcon
                data={ receiveSteps }
                title='How To Receive Money Using Crypto'
                allowHtml
              />
              <a href='/receive' className='btn-green'>
                Receive Money
              </a>
            </div>
            <div className='frame'>
              <ReceiveFrame />
            </div>
          </div>
        </div>
      </section>
      <section className='bg-color'>
        <BgColors />
      </section>
      <section id='Testimonial' className='customers'>
        <div className='pagewrap'>
          <h2 className='title'>What Our Wonderful Customers Say About Us</h2>
          <Testimonial data={ testimonials } />
        </div>
      </section>

      {/* <section id='Share' className='token'>
        <div className='pagewrap'>
          <h2 className='title'>$SHARE Token</h2>
          <div className='token-flex'>
            <div className='share-logo'>
              <img src={ tokenLogo } alt='easy share token logo' />
            </div>
            <article>
              <p>
                The $SHARE Token is a Governance token that will serve as the
                Protocol token of the Easyshare ecosystem, where Hodlers can use
                it to Stake to earn rewards, propose or vote for new protocol
                technology changes and upgrades, and also use it as collateral,
                when subscribed as a $ShareHodler Agents, to distribute
                remittances in cryptocurrency or local fiat currency.
                <br />
                <br />
                There will be an initial 250,000 $SHARE tokens minted and 20%
                will be made available for both private and public sale.
              </p>

              <a
                rel='noreferrer'
                target='_blank'
                href='https://token.easyshare.africa/'
                className='btn-green'>
                Read More
              </a>
            </article>
          </div>
        </div>
      </section> */}
      
      {/* <section className='as-seen'>
        <h2 className='title'>As Seen On</h2>
        <div className='press'>
          { listToMatrix( pressList, 4 ).map( ( list, index ) => (
            <div key={ `row_${ index + 1 }` } className={ `row_${ index + 1 }` }>
              { list.map( ( press ) => (
                <a href={ press.url } key={ press.alt } rel="noreferrer" target="_blank">
                  <img
                    src={ press.img }
                    alt={ press.alt }
                    className='press-image'
                  />
                </a>
              ) ) }
            </div>
          ) ) }
        </div>
      </section>
     */}
    </div>
  );
}

export default Home;
