import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import Error from "./index";
import "./Error.scss";

const Button = ( { cb, className } ) =>
{
    const history = useHistory();

    const handleClick = () =>
    {
        cb();
        history.push( "/" );
    };

    return <button
        className={ className }
        onClick={ handleClick }>Go to home page</button>;
};

export class ErrorBoundary extends Component 
{
    state =
        {
            hasError: false,
            error: '',
        };

    static getDerivedStateFromError ( error )
    {
        return { hasError: true, error };
    }

    clearError = () => this.setState(
        {
            hasError: false,
            error: '',
        } );


    render ()
    {
        if ( this.state.hasError )
        {
            return <section className="Boundary">
                <Error error={ this.state.error?.message  || "Error!" } />

                <div className="body">

                    <h1 className="oops">Ooops</h1>

                    <p className="message">Something went wrong.</p>

                    <Button className="link" cb={ this.clearError }>Go to home page</Button>

                </div>

            </section>;
        }

        return this.props.children;
    }

}

export default ErrorBoundary;
