import { userConstants } from "./User.constants";

export default function UserReducer ( state, action )
{
  switch ( action.type )
  {
    case userConstants.REGISTER_SUCCESS:
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        auth: action.auth,
      };

    case userConstants.LOGIN_FAILURE:
    case userConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: { userName: "" },
      };
    case userConstants.REFRESH_REQUEST:
      return {
        ...state,
        isRefreshing: true,
      };

    case userConstants.REFRESH_SUCCESS:
      return {
        ...state,
        isRefreshing: false,
      };
    //TODO add refresh error
    case userConstants.TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: action.transactions,
      };
    case userConstants.COMMISSION_SUCCESS:
      return {
        ...state,
        commissions: action.commissions,
      };

    case userConstants.PROFILE_SUCCESS:
      return {
        ...state,
        user: action.profile,
      };
    case userConstants.REFERRALS_SUCCESS:
      return {
        ...state,
        referrals: action.referrals,
      };
    default: {
      throw new Error( `Unhandled action type: ${ action.type }` );
    }
  }
}
