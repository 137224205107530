/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React,{useEffect, useState} from 'react'
import { useHistory, useLocation} from 'react-router-dom'
import { Header, NavLogo,Spinner } from "../../../../components";
import {useUserAction} from '../../Factory'
import checkPasswordStrength from '../../passwordGauge';

import './SetNewPassword.scss'

const CompleteForgetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState("");
  
    const [show, setShow] = useState(false);
    const [gauge, setGauge] = useState('');
    const [success, setSuccess] = useState(false);
  
    const [token,setToken] = useState(null)
    const [mail,setMail] = useState('')

    const [showTooltip, setShowTooltip] = useState(false);
    const [inputErrors, setInputErrors] = useState(false);
  
    const [newPassword, setNewPassword] = useState({
        password:{
            value:'',
            error:'required'
        },
        confirmPassword:{
            value:'',
            error:'required'
        }
    })

    const { password,confirmPassword } = newPassword

    const location = useLocation()
    const history = useHistory()

    useEffect(()=>{
      const token = new URLSearchParams(location.search).get('token')

      // remove the token from the url to prevent referer leakage
      history.push(location.pathname)

      userAction
      .validateResetToken(token)
      .then((mail) => {
        setToken(token)
        setMail(mail?.email)
      })
      .catch((err)=> {
        setServerError(Array.isArray(err) ? err.join(",") : err);
      })
    },[])


    const userAction = useUserAction();


    const onChange = (e) => {
        const { value, name } = e.target;
        let error =''
        if (name === "confirmPassword") {
            if (value !== password.value) {
                error = "passwords do not match!";
              } else {
                error = "";
              }
        }
        if (name === "password" && password.value !== '') {
            error = ''
        }
        setNewPassword(() => ({
            ...newPassword,
            [name] : {value: e.target.value,error}
        }))
    }


  // make the submit button appear disabled if there are any errors with the email field
        function isValid() {
            return !(password.error || confirmPassword.error) && gauge === 'strong';
        }

  // toggle password between show and hidden
  const togglePassword = (e) => setShow(() => !show);

  const redirect = () => setTimeout(() => {
    history.push('/affiliate/login')
  }, 5000)

  // check and show the  password strength onKeyUp
  const runGauge = () => {
  let gaugeValue = checkPasswordStrength(password.value)
    setGauge(gaugeValue)
  }

    const handleSubmit = (e) => {
        e.preventDefault()
       
        if (gauge !== 'strong') {
            setShowTooltip(true)
          }
      
          if (password.error || confirmPassword.error || gauge !== 'strong') {
            setInputErrors(true)
            setTimeout(() => {
              setInputErrors(false)
            }, 6000)
            return;
          }
             
          setLoading(true);
        setServerError("");
        userAction
        .createNewPassword({
            token,
            password:password.value,
            confirmPassword:password.value
        })
          .then((status) => {
            setLoading(false);
            setSuccess(true)
          })
          .catch((err) => {
            setLoading(false);
            setSuccess(false)
            setServerError(Array.isArray(err) ? err.join(",") : err);
          });
    }

    return (
        <div className='Affiliate_login'>
        <NavLogo isCenter />
        <div className="MultiStep">
            <Header />

            <section className="formWrap">
                <h3 className="formTitle">Password Recovery</h3>

                {" "}
                {serverError && (
                    <span className='error' style={{ textAlign: "center", display: "block" }}>
                    {serverError}
                    </span>
                )}

{           success && !serverError ? 
                    <div className="success-message">
                        <i className="far fa-check-circle"></i>
                        <h4>
                            Congrats.
                        </h4>
                        <p>
                            Your password has been reset.
                        </p>
                        <p>
                            You will be redirected to the login page in a few seconds, or click 
                        </p>

                        <span className='link'
                        onClick={()=>history.push('/affiliate/login')} 
                        >Log in</span>

                        <p style={{display:'none'}}>{redirect()}</p>              
                    </div>
                    :     
                <>
                {mail && (<p style={{textAlign:'center'}}>Welcome {mail}</p>)}
                <header>Enter your new password below</header>

                <div className="form-row">
                    <label htmlFor="email">Password
                    {
                    inputErrors && password.error && (
                    <span className='error'>  ({password.error})</span>
                    )}
                    </label>
                    <input 
                    type={`${show ? "text" : "password"}`} 
                    name="password" 
                    id="password" 
                    placeholder='password' 
                    required 
                    value={password.value} 
                    onChange={onChange} 
                    onKeyUp={runGauge}
                    onFocus={() => setShowTooltip(true)}
                    onBlur={() => setShowTooltip(false)}
                    />
                    <i
                    className={`far ${show ? "fa-eye-slash" : "fa-eye"}`}
                    id='togglePassword'
                    onClick={togglePassword}></i>
                </div>
                {
                showTooltip && <div 
                className="tooltip"
                id={gauge === 'strong' ? 'success' : ''}
                >
                <i className="fas fa-info-circle"></i>
                <span>Password (at least 8 characters, containing a lowercase, uppercase, number and a special character)</span>
                </div>}

                <div className='passwordStrength'>
            <progress
              className={
                gauge === 'weak'
                  ? "weak"
                  : gauge === 'fair'
                    ? "fair"
                    : gauge === 'good'
                      ? "good"
                      : gauge === 'strong'
                        ? "strong"
                        : ""
              }
              value={
                password.value === '' ? "0" : gauge === 'weak' ? "10" : gauge === 'fair' ? "40" : gauge === 'good' ? "65" : gauge === 'strong' ? "100" : "0"
              }
              max='100'></progress>
            <p>
              {password.value === ''
                ? "Input password"
                : gauge === 'weak'
                  ? "Weak"
                  : gauge === 'fair'
                    ? "Fair"
                    : gauge === 'good'
                      ? "Good"
                      : gauge === 'strong'
                        ? "Strong"
                        : "Input password"}
            </p>
          </div>

                <div className="form-row">
                <label htmlFor="email">Repeat Password
                {
                    inputErrors && confirmPassword.error && (
                    <span className='error'>  ({confirmPassword.error})</span>
                    )}
                </label>
                    <input 
                    type={`${show ? "text" : "password"}`} name="confirmPassword" id="confirmPassword" placeholder='repeat password' required value={confirmPassword.value} onChange={onChange} 
                    />
                    <i
                    className={`far ${show ? "fa-eye-slash" : "fa-eye"}`}
                    id='togglePassword'
                    onClick={togglePassword}></i>
                </div>

                <button 
                type="submit"
                className='submit'
                onClick={handleSubmit}
                id={!isValid() ? 'disabled' : ''}
                >
                   {loading && <Spinner light />} Submit
                </button>
                </>
                }

            </section>
        </div>
    </div>
    )
}

export default CompleteForgetPassword
