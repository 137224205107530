import React from "react";
import "../Form.scss";
import DPD from "./DropDown";
import { Spinner } from "../../index";
import { CurrencyProvider, CoinProvider } from "../../../_helpers";
import { useCalculator } from "../../../hooks";
import { withProvider } from "../../../hocs";
import CurrencyInput from "react-currency-input-field";
import { ReactComponent as Info } from "../../../assets/images/info.svg";
import ReactTooltip from "react-tooltip";
import { format } from "../../../utils";
import { Link } from "react-router-dom";


function TestCalc({ isFromRequest }) {
  const {
    send,
    receive,
    errors,
    touched,
    currencies,
    refs,
    isLoaded,
    setSend,
    setReceive,
    isValid,
  } = useCalculator({
    country: "NGN",
    minAmount: +(process.env.REACT_APP_MIN_AMOUNT || 0),
    maxAmount: +(process.env.REACT_APP_MAX_AMOUNT || Infinity),
    disableMinMaxValidation: !!+process.env.REACT_APP_DISABLE_MIN_MAX,
  });

  const tooltip = React.useRef();

  React.useEffect(() => {
    if (errors.limitError.isHigh) {
      ReactTooltip.show(tooltip.current);
    }
  }, [tooltip, errors.limitError.isHigh]);

  React.useEffect(() => {
    sessionStorage.setItem(
      "homeEntries",
      JSON.stringify({ values: refs.values, send, receive })
    );
  }, [refs.values, send, receive]);

  return (
    <div className='homeCalc'>
      <div className='form_wrapper'>
        <div className='form-row'>
          <label htmlFor='from'>
            You send {errors.send && touched && <small>({errors.send})</small>}
            {errors.limitError.isHigh && (
              <span
                ref={tooltip}
                className='info'
                data-tip={`please chat with us to send <br /> amount greater than $${format(
                  process.env.REACT_APP_MAX_AMOUNT
                )}`}
                data-background-color='red'
                data-multiline={true}
                data-text-color='#fff'>
                <Info />
              </span>
            )}
          </label>

          <div className='with_dropdown'>
            <div className='dropdown'>
              {isLoaded ? (
                <DPD
                  data={currencies}
                  type='large'
                  value={send?.label}
                  onChange={setSend}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: "20px 10px",
                  }}>
                  <Spinner light />
                </div>
              )}
            </div>
            <small className='estm'>&#8776;</small>
            <CurrencyInput
              ref={refs.sendRef}
              value={refs.values.send}
              name='send'
              placeholder='Please enter a number'
              defaultValue={0}
              min={0}
              prefix={send?.currencySymbol}
              onValueChange={(value, name) =>
                refs.handleChange({ value, name })
              }
            />
          </div>
        </div>

        <div className='form-row'>
          <label htmlFor='to'>
            Recipient receives{" "}
            {errors.receive && touched && <small>({errors.receive})</small>}
          </label>

          <div className='with_dropdown'>
            <div className='dropdown'>
              {isLoaded ? (
                <DPD
                  data={send?.receive}
                  value={receive?.label}
                  onChange={setReceive}
                  type={"large"}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: "20px 10px",
                  }}>
                  <Spinner light />
                </div>
              )}
            </div>
            <small className='estm'> &#8776;</small>
            <CurrencyInput
              ref={refs.receiveRef}
              name='receive'
              value={refs.values.receive}
              placeholder='Please enter a number'
              defaultValue={0}
              min={0}
              prefix={receive?.currencySymbol}
              onValueChange={(value, name) =>
                refs.handleChange({ value, name })
              }
            />
          </div>
        </div>
        <Link
          // eslint-disable-next-line no-script-url
          to={
            isValid()
              ? `/send?origin=home${isFromRequest ? "&frmReq=true" : ""}`
              : "/#"
          }
          className={`btn-green ${isValid() ? "" : "is-disabled"}`}>
          Get Started.
        </Link>
      </div>
      <p className='ref'>
        Send up to $2,500 daily to any mobile wallet or bank account <br />{" "}
        using crypto.
      </p>
      <ReactTooltip />
    </div>
  );
}

export const HomeCalc = withProvider(CoinProvider)(
  withProvider(CurrencyProvider)(TestCalc)
);
