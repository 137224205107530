import React from "react";
import { format } from "../../utils";
import FinalStep from "./FinalStep";
import ReactTooltip from "react-tooltip";

function WalletDetails(props) {
  const [showFinalStep, setShowFinalStep] = React.useState(false);
  const [indic8Cpd, setIndic8Cpd] = React.useState(false);
  const inputRef = React.useRef(null);

  const {
    data: {
      order,
      core: { receiveCurrency: UIrecv, sendCurrency: UIsend },
      transactionalState,
    },

  } = props;

  const {
    payWithAmount,
    sendAmount,
    receiveEstimatedAmount,
    receiveCurrency,
    payWithCrypto,
    estimatedReceiveAmount,
    tokenDto,
    cryptoCurrency,
    equivalentCryptoAmount
  } = order?.data || {};
  const { generatedWallet } = order?.data || {};

  //  const goEdit = () => changePage("calc",true);

  function handleCopy() {
    if (inputRef.current) {
      inputRef.current.select();
      inputRef.current.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      setIndic8Cpd(true);
    }
  }

  React.useEffect(() => {
    let timer = null;
    if (indic8Cpd) {
      timer = setTimeout(() => setIndic8Cpd(false), 1000);
    }
    return () => clearTimeout(timer);
  }, [indic8Cpd]);
 

  console.log(UIsend);
  const accountDetails = transactionalState
  const firstName = transactionalState.accountDetails?.accountName?.split(" ")[0];
  function TransactionSummary() {
    return (
      <div className='tsummary'>
        <div>
          <span>Amount to send</span>
          <span>
            {equivalentCryptoAmount} {cryptoCurrency}{" "}
          </span>
        </div>

        <div>
          <span>{UIsend?.label} equivalent</span>
          <span>
            {UIsend?.currencySymbol} {format(sendAmount)}
          </span>
        </div>
      </div>
    );
  }

  return showFinalStep ? (
    <FinalStep
      name={firstName}
      receive={format(estimatedReceiveAmount)}
      receiveCurrency={receiveCurrency}
      back={() => setShowFinalStep(false)}
    />
  ) : (
    <div className='walletview'>
      <p className='formMessage'>
        to <span className='emph bold'>{firstName}</span>
        <br />
        {accountDetails.bankName}
      </p>
      <TransactionSummary />
      <div className='user_receive_info'>
        <span className={`name`}>
          <span>{firstName} receives </span>
          <span className='approx'>approximately</span>{" "}
          <span
            className='aprox_info'
            data-place='bottom'
            data-tip={`This amount is an approximation and <br /> 
            the beneficiary may receive more or <br/> 
            less depending on the value received <br /> at confirmation.`}
            data-background-color='#d3efea'
            data-multiline={true}
            data-text-color='#303030'>
            <i className='fa fa-info-circle'></i>
          </span>{" "}
        </span>

        <span className='receive_amount'>
            {'₦'}&nbsp;{format(estimatedReceiveAmount)}
        </span>
      </div>
      <input
        type='text'
        value={generatedWallet?.address}
        readOnly
        ref={inputRef}
        className='visuallyHidden'
      />
      <div className='qrview'>
        <p>
          <span>Price will refresh in</span>
          <span>01 : 30 mins</span>
        </p>
        <div className='qrbox'>
          <div className='qr'>
              <img src={generatedWallet?.qrCode} alt='dummy qr' />
          </div>
          <div className='qrinfo'>
            <p>
              Copy or scan The Easyshare wallet below and Send the exact{" "}
              <small className='emph'>
                <b>
                  {payWithCrypto}
                  {tokenDto && <span>{tokenDto?.tokenName}</span>}
                </b>
              </small>{" "}
              amount from your {payWithCrypto} wallet.{" "}
              <small className='emph'>{firstName}</small> will receive the{" "}
              <small className='emph'>{receiveCurrency}</small>&nbsp; equivalent
              after confirmation.
            </p>
            <div className='cpwrap'>
              {indic8Cpd && (
                <button className='cindi'>Crypto address copied</button>
              )}
              <button onClick={handleCopy}>Copy address</button>
            </div>
          </div>
        </div>
        <ReactTooltip />
        <button
          className='submit'
          type='submit'
          onClick={() => setShowFinalStep(true)}>
          I have sent crypto
        </button>
      </div>
    </div>
  );
}

WalletDetails.propTypes = {};

export default WalletDetails;
