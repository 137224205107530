import React from "react";
import "./Home.scss";
import { SendFrame, ListIcon, NavLogo } from "../../../components";
import { sendSteps } from "../../Home/data";
import ban from "../../../assets/images/headerBanner.png";
import { Link } from "react-router-dom";
import one from "../../../assets/images/1.svg";
import two from "../../../assets/images/2.svg";
import three from "../../../assets/images/3.svg";
import christina from "../../../assets/images/christina-wocintechchat-com-vLwH8bWoi_8-unsplash 1.png";
import shawn from "../../../assets/images/shawn-fields-zsppCWsxJy0-unsplash 1.png";
import person from "../../../assets/images/person-giving-friend-cash-gift-envelope 1.png";
function Home({match}) {
  return (
    <div className="Home">
      <NavLogo />
      <header className="banner">
        <div className="pagewrap">
          <div className="content left">
            <section className="hero-text">
              <div className="text-wrap">
                <h2 className="header">
                  Welcome to the Easyshare Affiliate Program.
                </h2>
              </div>
              <div className="cta-row">
                <Link className="loginButton" to={`${match.path}/login`}>
                  log in
                </Link>
                <Link className="signupButton" to={`${match.path}/signup`}>
                  sign up
                </Link>
              </div>
            </section>

            <section className="home-illustration">
              <img
                src={ban}
                alt="a phone showing a mobile version of easyshare site"
              />
            </section>
          </div>
        </div>
      </header>
      <section className="steps">
        <div className="pagewrap">
          <h3 className="title">How The Affiliate Program Works</h3>
          {/* SEND */}
          {/* <div className='steps_info send'>
            <div className='frame'>
              <SendFrame />
            </div>
            <div className='list'>
              <ListIcon
                data={ sendSteps }
                title='How To Send Money Using Crypto'
                allowHtml
              />
              <a href='/send' className='btn-green'>
                Send Money
              </a>
            </div>
          </div> */}
          <div className="container reverse">
            <div className='img__num'>
          <div className="icon-number">
              <img src={one} alt="number one" className="step_picture" />
            </div>
              <img className='headImage' src={christina} alt="registering on laptop" />
            </div>

            <div className="body">
              <h2 className="head">Register and set up</h2>
              <p className="description">
                In less than two minutes, Get signed up and verified with your
                email. Then gain access to your dashboard.
              </p>
              <Link to={`${match.path}/signup`} className="register">Register Now</Link>
            </div>
           
          </div>

          <div className="container">
            <div className='img__num'>
          <div className="icon-number">
              <img src={two} alt="number two" className="step_picture" />
            </div>
              <img className='headImage' src={shawn} alt="friends talking" />
            </div>

            <div className="body">
              <h2 className="head">Share Link</h2>
              <p className="description">
                Copy your unique Easyshare link from your dashboard and share
                with family and friends.
              </p>
            </div>
            
          </div>

          <div className="container reverse">
            <div className='img__num'>
          <div className="icon-number">
              <img src={three} alt="number three" className="step_picture" />
            </div>
              <img className='headImage' src={person} alt="person giving money" />
            </div>

            <div className="body">
              <h2 className="head">Earn</h2>
              <p className="description">
                Once your referrer sends or receives money, you earn! The
                dashboard provides detailed overview of your earning.
              </p>
            </div>
            
          </div>
        </div>
      </section>
      <section className="dotsection"></section>
    </div>
  );
}

Home.propTypes = {};

export default Home;
