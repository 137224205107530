import React from "react";
import { Alpha, Pointer, Notification, Copy, Share, Arrow } from "../icons";
import "./index.scss";

export function ListIcon({ data, title, allowHtml }) {
  const iconMap = {
    pointer: <Pointer />,
    alpha: <Alpha />,
    notification: <Notification />,
    copy: <Copy />,
    share: <Share />,
    arrow: <Arrow />,
  };

  return (
    <ul className='iconList'>
      {/*the fixer icon at the top is set to display none, due to a weird behaviour
       (the paths icon in the svg gets cut out when it's removed from dom)*/}
      <span className='fixer'>{iconMap["pointer"]}</span>
      <h3>{title}</h3>
      {data?.length
        ? data.map(({ icon, text }, i) => (
            <li key={`${i}_${text.substring(0, 1)}`}>
             <div className={`icon_box ${icon}`}>
                 {iconMap[icon]}
             </div>
              {!allowHtml ? (
                <span>{text}</span>
              ) : (
                <span dangerouslySetInnerHTML={{ __html: text }} />
              )}
            </li>
          ))
        : null}
    </ul>
  );
}

ListIcon.propTypes = {};
