import React from "react";
import userReducer from "./User.reducer";
import UserAction from "./User.actions";
import { checkedJsonStorage } from "../../../../utils";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

export function UserProvider ( { children } )
{
  var [ state, dispatch ] = React.useReducer( userReducer, {
    isAuthenticated: false,
    auth: checkedJsonStorage( "auth" ),
    user: checkedJsonStorage( "user" ),
    commissions: null,
    referrals: {},
    transactions: [],
    isInitialLoading: false
  } );




  return (
    <UserStateContext.Provider value={ { ...state } }>
      <UserDispatchContext.Provider value={ dispatch }>
        { children }
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}


export function useUserDispatch ()
{
  var context = React.useContext( UserDispatchContext );
  if ( context === undefined )
  {
    throw new Error( "useUserDispatch must be used within a UserProvider" );
  }
  return context;
}

export function useUserState ()
{
  var context = React.useContext( UserStateContext );
  if ( context === undefined )
  {
    throw new Error( "useUserState must be used within a UserProvider" );
  }
  return context;
}

export function useUserAction ()
{
  const dispatch = useUserDispatch();
  const action = React.useMemo(
    () => new UserAction( dispatch ),
    [ dispatch ]
  );

  return action;
}

export function useUserStateAndAction ()
{
  const state = useUserState();
  const action = useUserAction();

  return { state, action };
}

// ###########################################################
