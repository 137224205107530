import React, { useEffect } from "react";
import BankDetails from "./BankDetails";
import SelectOperator from "./SelectOperator";
import { useUiState, useTransactionAction } from "../../_helpers";
import { baseUrl } from "../../utils";

const Choose = ( { setMode, modeText } ) =>
{
  return (
    <div>
      <p
        className='formMessage'
        dangerouslySetInnerHTML={{ __html: modeText?.main }} />
    

      <section className='mode'>
        <p>{modeText?.primary}</p>

        <button onClick={() => setMode(1)} className='choose-mode'>
          Account number
          <i className='fas fa-caret-right'></i>
        </button>

        <button onClick={() => setMode(2)} className='choose-mode'>
          Phone number
          <i className='fas fa-caret-right'></i>
        </button>
      </section>
    </div>
  );
};

function ChooseRecieveMode ( { modeText, text , ...globalProps  } )
{
  const Taction = useTransactionAction();
  const { UiState, UiActions } = useUiState();
 

  const chooseMode = ( page ) =>
  {
    UiActions.setRM( page );
    //clear account det
    Taction.setAccountField( "country", "" );
    Taction.setPhoneNumber("");
    Taction.setAccountField("accountNumberType","");
  };

  const BankProps = {
    ...globalProps,
    setField: Taction.setAccountField,
    setBanks: UiActions.setBanks,
    setAccountData: Taction.setAccountDetails,
    text:text.bank
  };

  useEffect(() => {
    fetch(`${baseUrl}/countries`)
      .then(res => res.json())
      .then(res => {

        const countries = res.map(i => {
          return {
            short: i.currencyCode,
            country: i.name,
            code: i.currencyCode
          };
        });
        console.log(countries[0]);
        UiActions.setCountries([ countries[0]]);
      })
      .catch(err => console.error(err))
  }, [])

  const components = [
    <Choose modeText={ modeText } setMode={ chooseMode } />,
    <BankDetails back={ () => chooseMode( 0 ) } { ...BankProps }  />,
    <SelectOperator
      back={ () => chooseMode( 0 ) }
      { ...globalProps }
      setObanks={ UiActions.setObanks }
      setField={ Taction.setAccountField }
      setAccountData={ Taction.setAccountDetails }
      setPaymentMethod={ Taction.setPaymentMethod }
      setPhone={ Taction.setPhoneNumber }
     text={text.operator}
    />,
  ];

  return components[ UiState.receiveMode ];
}

export default ChooseRecieveMode;
