import React from "react";
import queryString from "query-string";
import { UserService } from "../../Factory/User/User.service";
import "./verify.scss";

function Verify(props) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [verified, setVerified] = React.useState(false);
  const token = queryString.parse(window.location.search)?.token;

  React.useEffect(() => {
    UserService.confirmUser(token)
      .then((success) => {
        setLoading(false);
        setVerified(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || "failed to verify user");
      });
  }, [token]);

  function displayUi() {
    if (loading) {
      return <h4> <i className="fa fa-spin fa-spinner"></i> verifying</h4>;
    }
    if (error) {
      return <h4>{error} </h4>;
    }
    if (verified) {
      return <h4>account has been verified</h4>;
    }
  }

  return (
    <div className='verify'>
      <div className="v-box">{displayUi()}</div>
    </div>
  );
}

Verify.propTypes = {};

export default Verify;
