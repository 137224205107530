import React from "react";
import { Accordion, LeafySmallLogo } from "../../components";
//import PropTypes from 'prop-types'
import "./Info.scss";
import { faqData } from "./data";
import { Link } from "react-router-dom";

function Faq(props) {
  const [search, setSearch] = React.useState("");
  const handleChange = (e) => setSearch(e.target.value);

  const filtered = faqData.filter(
    ({ title }) =>
      search === "" || title.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <div className='Faq'>
      <section className='header'>
        <div className='logo'>
          <Link to='/'>
            <LeafySmallLogo />
          </Link>
        </div>
        <div className='search'>
          <label>
            <i className='fa fa-search'></i>
          </label>
          <input
            type='text'
            placeholder='How can we help you'
            onChange={handleChange}
          />
        </div>
      </section>
      {!!!filtered.length && (
        <div className='isEmpty title'>
          <h2>oops!! we couldnt find anything</h2>
        </div>
      )}
      <Accordion
        data={filtered}
        style={{ marginTop: !!!filtered.length ? 0 : 100 }}
      />
    </div>
  );
}

Faq.propTypes = {};

export default Faq;
