

export const userConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  TRANSACTION_SUCCESS: "TRANSACTION_SUCCESS",
  COMMISSION_SUCCESS: "COMMISSION_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  PROFILE_SUCCESS: "PROFILE_SUCCESS",
  REFRESH_REQUEST: "REFRESH_REQUEST",
  REFRESH_SUCCESS: "REFRESH_SUCESSS",
  TOP_REFERAL_SUCCESS: "TOP_REFERAL_SUCCESS",
  REFERRALS_SUCCESS: "REFERRALS_SUCCESS",
  REFERRALS_FAILURE: "REFERRALS_FAILURE",
};
