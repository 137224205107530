import React, { useState } from "react";
import { string as yupString } from "yup";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "yup-phone";
import toast from 'react-hot-toast';

import side from "../../../../assets/images/Polygon.png";
import up from "../../../../assets/images/Polygonup.png";
import { Spinner } from "../../../../components";
import { useUserAction } from "../../Factory";

const validationSchema = {
  name: yupString()
    .required()
    .required("username is required")
    .matches(
      /^[a-zA-Z0-9_]*$/gm,
      "only alphabet, numbers and underscore allowed"
    ),
  firstName: yupString()
    .required("your first name is required")
    .matches(/^[A-Za-z0-9 ]+$/, "name is not valid"),

  lastName: yupString()
    .required("your first name is required")
    .matches(/^[A-Za-z0-9 ]+$/, "name is not valid"),

  phoneNumber: yupString()
    .phone("NG", true, "this is not a valid phone-number")
    .required("Phone number is required"),
};

const Profile = ({ user }) => {
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const userActions = useUserAction();
  const refererLink =
    process.env.REACT_APP_BASEURL + "?affiliateId=" + user?.refererId;
  const initialValues = {
    name: {
      value: user?.name || "",
      touched: "",
      error: user?.name ? "" : "required",
    },
    firstName: {
      value: user?.profile?.firstName || "",
      touched: "",
      error: user?.profile?.firstName ? "" : "required",
    },
    lastName: {
      value: user?.profile?.lastName || "",
      touched: "",
      error: user?.profile?.lastName ? "" : "required",
    },
    phoneNumber: {
      value: user?.phonenumber || "",
      touched: "",
      error: user?.phonenumber ? "" : "required",
    },
  };

  const [updateProfileDto, setDto] = useState(initialValues);

  const { firstName, lastName, phoneNumber, name } = updateProfileDto;

  const handleClick = () => setClicked(() => !clicked);

  const cancel = () => setDto(initialValues);

  const validate = (name, value) => {
    let error = "";
    try {
      value = validationSchema[name]?.validateSync(value);
    } catch (err) {
      if (err.name === "ValidationError") {
        error = err.errors.join(",");
      }
    }
    return { value, error };
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    const { value: val, error } = validate(name, value);
    setDto(() => ({
      ...updateProfileDto,
      [name]: { value: val.trim(), touched: true, error },
    }));
  };

  const isValid = () => {
    return !(name.error || firstName.error || lastName.error || phoneNumber.error);
  };

  const submitChanges = (e) => {
    e.preventDefault();
    const {
      name: { value: name },
      firstName: { value: firstName },
      lastName: { value: lastName },
      phoneNumber: { value: phoneNumber },
    } = updateProfileDto;

    setLoading(true);
    setServerError("");
    userActions.UpdateProfileInfo({
      name,
      phonenumber:phoneNumber,
      profile: {
        firstName,
        lastName,
      },
    })
      .then((status) => {
        setLoading(false);
        setSuccessMsg(status?.message || "Profile Updated");
        handleClick();
        toast.success(status?.message || "Profile Updated",{
          duration: 9000,
        })
      })
      .catch((err) => {
        setLoading(false);
        setServerError(Array.isArray(err) ? err.join(",") : err);
      });
  };

  function handleOnChange(value) {
    value = value ? formatPhoneNumberIntl(value) : "";
    if (isValidPhoneNumber(value)) {
        setDto(() => ({
          ...updateProfileDto,
          phoneNumber: { value: value.trim(), touched: true, error:"" },
        }));
    } else {
      setDto(() => ({
        ...updateProfileDto,
        phoneNumber: {
          value: value.trim(),
          touched: true,
          error: "this is not a valid phone-number",
        },
      }));
    }
   
  }

  return (
    <div className='dropdown_section'>
      <button
        className={`dropdown ${clicked && "active"}`}
        onClick={handleClick}>
        {successMsg ? <p className='success'>{successMsg}</p> : <p>Profile</p>}
        <img src={clicked ? up : side} className='icon' alt='caret' />
      </button>

      <div id='dropdown_content' className={`${clicked ? "show" : "hide"}`}>
        <form id='profile' className='form'>
          {serverError && <small className='form-error'>{serverError}</small>}
          <div className='form_group'>
            <label htmlFor='Username'>Referral Link: </label>
            <small>
              {!user?.isConfirmed || !user?.refererId
                ? "verify your email to see your referal link"
                : refererLink}
            </small>
          </div>
          <br />

          <div className='form_group'>
            <label htmlFor='Username'>Username</label>
            <input
              type='text'
              id='Username'
              name='name'
              placeholder='Username'
              value={name.value}
              onChange={onChange}
            />
            {name.touched && name.error && (
              <small className='error'> ({name.error})</small>
            )}
          </div>
          <br />
          <br />
          <div className='form_group'>
            <label htmlFor='firstName'>First Name</label>
            <input
              type='text'
              id='firstName'
              name='firstName'
              placeholder='first name'
              value={firstName.value}
              onChange={onChange}
            />
            {firstName.touched && firstName.error && (
              <small className='error'> ({firstName.error})</small>
            )}
          </div>
          <br />
          <br />
          <div className='form_group'>
            <label htmlFor='lastName'>Last Name</label>
            <input
              type='text'
              id='lastName'
              name='lastName'
              placeholder='last name'
              value={lastName.value}
              onChange={onChange}
            />
            {lastName.touched && lastName.error && (
              <small className='error'> ({lastName.error})</small>
            )}
          </div>
          <br />
          <br />
          <div className='form_group'>
            <label htmlFor='phoneNumber'>Phone Number</label>
            <PhoneInput
              defaultCountry='NG'
              placeholder='Enter phone number'
              value={phoneNumber.value}
              onChange={handleOnChange}
              international={true}
            />
            {phoneNumber.touched && phoneNumber.error && (
              <small className='error'> ({phoneNumber.error})</small>
            )}
          </div>
          <br />
          <br />
          <div id='save_changes'>
            <button
              onClick={submitChanges}
              disabled={!isValid()}
              className='submit_button'>
              {loading && <Spinner light />} Save Changes
            </button>
            <p onClick={cancel}>cancel</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
