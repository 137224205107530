import React, { useState, useRef } from "react";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <li className={`accordion__section  ${setActive}`}>
      <div className={`list_title ${setActive}`} onClick={toggleAccordion}>
        <h2>{props?.title}</h2>
        <span>
          <i className={`fa fa-chevron-down ${setRotate}`}></i>
        </span>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className='accordion__content'>
        <div
          className='accordion__text'
          dangerouslySetInnerHTML={{ __html: props?.content }}
        />
      </div>
    </li>
  );
}

export default Accordion;
