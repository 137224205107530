import React from 'react'
import { Link } from "react-router-dom";
import { format } from '../../utils';

function CopyLink({ data }) {
   const { accountDetails, requestId, receiveEstimatedAmount  } = data?.order || {}
   const [indic8Cpd, setIndic8Cpd] = React.useState(false);
   const inputRef = React.useRef(null)

   function handleCopy() {
     if (inputRef.current) {
       inputRef.current.select();
       inputRef.current.setSelectionRange(0, 99999); /*For mobile devices*/
       document.execCommand("copy");
       setIndic8Cpd(true);
     }
   }
   function buildRequestLink(){
     return window.location.protocol + "//" + window.location.host + `/?rId=${requestId}`;
   }
   React.useEffect(()=>{
       let timerId = null;
       if(indic8Cpd){
         timerId = setTimeout(()=>setIndic8Cpd(false),1000)
       }
       return ()=>clearTimeout(timerId)
   },[indic8Cpd])

    return (
      <div className='cpl'>
        <p className='formMessage' style={{ marginTop: 51 }}>
          All set!
          {/* You are can now request to receive money from family, friends,
          employer or business partners. */}
        </p>
        <div className='boldened'>
          <p>{accountDetails?.accountName}</p>
          <p>{accountDetails?.accountNumber}</p>
          <p>{accountDetails?.bankName}</p>

          <p className='amount'>
            {receiveEstimatedAmount && (
              <span>NGN {format(receiveEstimatedAmount)}</span>
            )}
          </p>
          
        </div>
        <p
          className='formMessage'
          style={{ margin: "20px auto 0", width: 233 }}>
          Simply copy your unique link below and share to receive deposits from
          anyone anywhere!
        </p>

        <input
          type='text'
          readOnly
          value={buildRequestLink()}
          ref={inputRef}
          className='visuallyHidden'
        />
        <div className='btn_home indic8'>
          {indic8Cpd && <p className>copied</p>}
        </div>
        <button className='submit' onClick={handleCopy}>
          Copy Link
        </button>
        <div className='btn_home'>
          <Link to='/'>Home</Link>
        </div>
      </div>
    );
}

CopyLink.propTypes = {

}

export default CopyLink

