import React from 'react'
import { GridBox, GridContainer  } from "../../../../components";
import { format } from '../../../../utils';
import { useUserState } from '../../Factory';
import Table from '../Table';


function Transaction(props) {

 const {  commissions, transactions } = useUserState();
    const columns = [
      {
        name: "email",
        label: "Email",
        style: {
          color: "rgba(48, 48, 48, 0.5)",
        },
      },
      {
        name: "amount",
        label: "Amount",
        option:"isMoney",
        style: {
          fontSize:18,
          fontWeight:600
        },
      },
      {
        name: "currency",
        label: "Currency",
      },
      {
        name: "country",
        label: "Country",
      },
      {
        name: "status",
        label: "Payout Status",
      },
    ];

   

    return (
      <div className='transactions'>
        <GridContainer>
          <GridBox title='REVENUE THIS WEEK'>
            <div className='info_count'>
              <h2>₦{format(commissions?.lastSevenDays || 0)}</h2>
            </div>
          </GridBox>

          <GridBox title='REVENUE THIS MONTH'>
            <div className='info_count'>
              <h2>₦{format(commissions?.lastOneMonth || 0)}</h2>
            </div>
          </GridBox>
          <GridBox title='TOTAL REVENUE'>
            <div className='info_count'>
              <h2>₦{format(commissions?.total || 0)}</h2>
            </div>
          </GridBox>
        </GridContainer>
        <div className='table_region'>
          <Table columns={columns} data={transactions?.content || []} title='Transactions' />
        </div>
      </div>
    );
}

Transaction.propTypes = {

}

export default Transaction

