import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ScrollToTop } from "../../../components";

export const PrivateRoute = ( { component: Component, ...rest } ) =>
{

  return (
    <Route
      { ...rest }
      render={ ( props ) =>
        localStorage.getItem( "auth" ) ? (
          <ScrollToTop>
            <Component { ...props } />
          </ScrollToTop>
        ) : (
          <Redirect
            to={ { pathname: "/affiliate/login", state: { from: props.location } } }
          />
        )
      }
    />
  );
};

export function PublicRoute ( { component: Component, ...rest } )
{
  return (
    <Route
      { ...rest }
      render={ ( props ) =>
        //if the user is logged in take them to app itself, else show
        localStorage.getItem( "auth" ) ? (
          <Redirect
            to={ {
              pathname: "/affiliate/dashboard",
              state: {
                from: props.location,
              },
            } }
          />
        ) : (
          React.createElement( Component, props )
        )
      }
    />
  );
}
