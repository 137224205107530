import React, { useState } from "react";
import toast from 'react-hot-toast'
//import PropTypes from 'prop-types'
import "./Settings.scss";
import Bank from "./Bank";
import ChangePassword from "./ChangePassword";
import Profile from "./Profile";

import { useUserStateAndAction } from "../../Factory";

function Settings({ modeText, text, ...globalProps }) {
  
  const [isSubmitting, setSubmitting] = useState(false);
  const { action, state:{ user } } = useUserStateAndAction();
  

  const onBankSubmit = (data, close) => {
    setSubmitting(true);
    action
      .addOrUpdateBankDetails(data)
      .then((bankDetails) => {
        close();
        setSubmitting(false);
        toast.success('bank details updated',{
          duration: 9000,
        })
      }).catch( err => {
         setSubmitting(false);
      })
      
  };

  return (
    <div className=''>
      <div className='settings'>
        <div className='settings_content'>
          <div className='title'>
            <h5> Settings</h5>
          </div>

          <div className='settings_dropdowns'>
            
            <ChangePassword />
            <Bank
              value={user?.bankDetails || {}}
              isSubmitting={isSubmitting}
              onSubmit={onBankSubmit}
            />
            <Profile user={user} />
          </div>
        </div>
      </div>
    </div>
  );
}

Settings.propTypes = {};

export default Settings;
