import React from 'react'
import { ReactComponent as Illust } from "../../assets/images/final.svg"
import { BackBtn } from "../index";
import { Link } from "react-router-dom";

function FinalStep({ back, receive, name, receiveCurrency }) {

   const openChat = () => {
      if (window?.LiveChatWidget) {
          window.LiveChatWidget.call('maximize');
       //Or do something else :)
     }
   };
  console.log(receive);
    return (
      <div className='finalStep'>
        <BackBtn to={back} />
        <p className='message'>
          <span className='emph'>
            {receiveCurrency} {receive}
          </span>{" "}
          will be sent to
          <br />
          <span className='emph'>{name}</span> after confirmation.
        </p>
        <div className='illustration'>
          <Illust width={"100%"} height='100%' />
        </div>
        <p className='footnote'>Thank you for choosing Easyshare</p>
        <button className='submit' type='submit' onClick={openChat}>
          Chat with us
        </button>
        <div className='btn_home'>
          <Link to='/'>Home</Link>
        </div>
      </div>
    );
}

FinalStep.propTypes = {

}

export default FinalStep

