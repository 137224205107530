import React from 'react';
import ErrorTop from "./Error";
import { Detector } from "react-detect-offline";

//avoid cors issue
//tip from https://github.com/cwise89/react-detect-offline/issues/74#issue-807084981
const config =
{
    url: `${ window.location.origin }`,
    interval: 10000
};

function OfflineDetector () 
{
    return (

        <Detector
            polling={ config }
            render={
                ( { online } ) => online ? null :
                    <ErrorTop error="You’ve encountered a connection error, please check your connection and try again" />
            }

        />
    );
}

export default OfflineDetector;
