import React from "react";
import { Link } from "react-router-dom";
import landing from "../../assets/images/landing4.png";
import "./HomeHero.scss";
import WithName from "./WithName";

function Normal ( { rId, affiliateId } )
{
  let affiliateTo = !!affiliateId ? `?affiliateId=${affiliateId}` : "";
  return (
    <div className='pagewrap'>
      <div className='content left'>
        <section className='hero-text'>
          {!!rId ? (
            <WithName rId={rId} />
          ) : (
            <>
              <div className='text-wrap'>
                <h2 className='header'>
                  A fast, reliable and affordable way to send and receive money
                  with anyone in Africa using crypto.
                </h2>

                <p className='message'>
                  Enjoy the freedom of sharing using crypto.
                  <br />
                  BTC, ETH, USDT and more coins supported
                </p>
              </div>
              <div className='cta-row'>
                <Link className='receive' to={`/receive${affiliateTo}`}>
                  Receive Money
                </Link>
                <Link to={`/send${affiliateTo}`}>Send Money</Link>
              </div>
            </>
          )}
        </section>

        <section className='home-illustration'>
          <img
            src={landing}
            alt='stylized easy share logo with people on each petal as a background'
          />
        </section>
      </div>
    </div>
  );
}

export default Normal;
