import React from "react";
import { Sidebar, Header, ReferralTable, Dashboard, Transactions, AddBankDetailsModal } from "./Components";
import { Route } from "react-router-dom";
import "./Layout.scss";
import { useUiState } from "../../_helpers";
import Settings from "./Components/Settings/Settings";
import { useUserAction, useUserState } from "./Factory";
import Logout from "./Logout";
import NotFound from "../NotFound";
import { useInterval } from '../../hooks';
import { UserService } from "./Factory/User/User.service";
import { Spinner } from "../../components";
import {Toaster} from 'react-hot-toast'

const fiveMins = 5 * 60 * 1000;
function Layout ( { match, location } )
{
  const { UiActions } = useUiState();
  const userAction = useUserAction();
  const { user } = useUserState();
  const [ loadingConfirm, setLoadingConfirm ] = React.useState(false);
  const [ isSent, setIsSent] = React.useState(false);
  const [ initIalLoading, setInitialLoading ] = React.useState(true);
   const [initialError, setInitialError] = React.useState("");
   const [reqX,setReqX] = React.useState(false)


  React.useEffect( () =>
  {
    UiActions.hideFooter();
    return () => UiActions.showFooter();
    //eslint-disable-next-line
  }, [] );

  React.useEffect( () =>
  {
    //set page load true
    setInitialError("");
    userAction.loadAll(true).then( ( all ) =>
    {
      //console.log();
      setInitialLoading(false)
    } ).catch( err=>{
       //set error message
       setInitialLoading(false);
       setInitialError(err);
    });
    //eslint-disable-next-line
  }, [] );

  useInterval( () =>
  {
    userAction.loadAll().then( ( all ) =>
    {
    } );
  }, fiveMins );

 // console.log();

  function handleConfirmSend(){
      setLoadingConfirm(true);
      setIsSent(false);
     
      UserService.resendConfirmationMail(window.location.host + "/affiliate/auth/confirm")
        .then((resp) => {
          setLoadingConfirm(false);
          setIsSent(true);
        })
        .catch((err) => {
          setLoadingConfirm(false);
        });
  }
  function handleRefresh(){
    window.location.reload(true)
  }

  const isMounted = (val) => {
    setReqX(()=> val)
  }

  if(initIalLoading){
    return (
      <div className='page_loader'>
        <Spinner center light showLogo />
        <h4>please be patient while we fetch your relevant info</h4>
      </div>
    );
  }

  if(initialError){
    return (
      <div className='page_loader'>
        <h3 className="cry-icon"><i className="fas fa-sad-cry"></i></h3>
        <h4 className="error">oops! sorry we couldn't fetch your details, please check your network connection</h4>
        <button  className="btn" onClick={handleRefresh}>refresh page</button>
      </div>
    );
  }
  return (
    <Route path={`${match.path}`}>
      <div className='affiliate_flex_wrapper'>
        {
          /** render this if there are no bank details*/
          !user?.bankDetails && <AddBankDetailsModal match={match} isMounted={isMounted}/>
        }
        
        <Sidebar match={match} location={location} />
        <div className='affiliate_main'>
          <Header />
          <div className='affiliate_content_view'>
            {!user?.isConfirmed && (
              <div className='top-alert'>
                <p>
                  a verification link has been sent to your mail.. did not
                  receive mail
                  {isSent && (
                    <span>
                      <i className='fa fa-check'></i> sent
                    </span>
                  )}
                  {loadingConfirm && (
                    <span>
                      <i className='fa fa-spin fa-spinner'></i> send
                    </span>
                  )}
                </p>

                <button onClick={handleConfirmSend}>Resend</button>
              </div>
            )}
            <Toaster
            position="bottom-center"
            reverseOrder={false}
            />
            <Route exact path={`${match.path}`}>
              <Dashboard match={match} reqCanceled={reqX} />
            </Route>
            <Route exact path={`${match.path}/:page`} component={MainView} />
          </div>
        </div>
      </div>
    </Route>
  );
}


function MainView ( { match } )
{

  const page = match.params.page;
  const pages =
  {

    "settings": <Settings />,
    "referrals": <ReferralTable />,
    "transactions": <Transactions />,
    "signout": <Logout />
  };

  return pages[ page ] ? pages[ page ] : <NotFound url="/affiliate/dashboard" />;
}

Layout.propTypes = {};

export default Layout;
