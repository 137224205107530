import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";

import { makeReq } from "../../utils";

import insta from "../../assets/images/insta.svg";
import medium from "../../assets/images/medium.svg";
import twitter from "../../assets/images/twitter.svg";
import telegram from "../../assets/images/telegram.svg";
import facebook from "../../assets/images/facebook.svg";
import linkedin from "../../assets/images/linkedin.svg";


import "./footer.scss";
import { useUiState } from '../../_helpers/UiContext';

const emailSchema = Yup.object().shape( {
  "sub-email": Yup.string().email( "Please enter a valid email" ).required( "Email is required" )
} );

function Footer ()
{
  const { UiState: { isFooterHidden } } = useUiState();
  const [ visible, setVisible ] = useState( false );

  const formik = useFormik( {
    initialValues: { "sub-email": '' },
    validationSchema: emailSchema,
    onSubmit: async values =>
    {
      const newsletterDto =
      {
        email: values[ "sub-email" ],
        "isSubscribed": true
      };

      await makeReq( "newsletter/subscribe",
        {
          method: "POST",
          body: JSON.stringify( newsletterDto )
        } );
      formik.resetForm();
      setVisible( true );
    }
  } );

  useEffect( () =>
  {
    let id;
    if ( visible )
    {
      id = setTimeout( () =>
      {
        setVisible( false );
      }, 3000 );
    }
    return () =>
    {
      clearTimeout( id );
    };
  }, [ visible ] );

  return !isFooterHidden ? (
    <>
      <footer className='footer'>
        <div className='pagewrap'>
          <div className='inner'>
            <section className='links-sub'>
              <div className='link-section'>
                <p>Legal</p>
                <ul>
                  <li>
                    <Link to='/privacy'>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to='/terms'>Terms and Conditions</Link>
                  </li>
                </ul>
              </div>

              <div className='link-section'>
                <p>Product</p>
                <ul>
                  <li>
                    <Link to='/send'>Send Money</Link>
                  </li>
                  <li>
                    <Link to='/receive'>Receive Money</Link>
                  </li>
                </ul>
              </div>

              <div className='link-section'>
                <p>Resources</p>
                <ul>
                  <li>
                    <a
                      className="blog"
                      rel='noreferrer'
                      target='_blank'
                      href='https://medium.com/@easyshareafrica'>

                      Blog
                      <div className="medium">
                        <img src={ medium } alt="medium logo" />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href='/faqs'>FAQs</a>
                  </li>
                </ul>
              </div>

              <div className='link-section last'>
                <p>Contact </p>
                <ul>
                  <li>
                    <a rel='noreferrer'
                      target='_blank' href="https://help.easyshare.africa/">Help</a>
                  </li>
                  <li>
                    <a href='mailto:hello@easyshare.africa'>
                      hello@easyshare.africa
                    </a>
                  </li>
                </ul>
              </div>

              <div className='link-section last form'>
                <p>Subscribe to Easyshare</p>

                { formik.touched[ "sub-email" ] && formik.errors[ "sub-email" ] ? (
                  <div>{ formik.errors.email }</div>
                ) : null }
                <form onSubmit={ formik.handleSubmit }>
                  <input
                    type='email'
                    name='sub-email'
                    id='sub-email'
                    placeholder='youremail@gmail.com'
                    onChange={ formik.handleChange }
                    onBlur={ formik.handleBlur }
                    value={ formik.values[ "sub-email" ] }
                  />
                  <button
                    disabled={ !formik.isValid || !formik.values[ "sub-email" ] }
                    type='submit'>
                    Subscribe
                  </button>
                </form>
              </div>
            </section>

            <section className='social-copyright'>
              <div className='links'>

                <a
                  className="linkedin"
                  rel='noreferrer'
                  target='_blank'
                  href='https://www.linkedin.com/company/easyshareafrica/'>
                  <img src={ linkedin } alt='linkedin logo' />
                </a>

                <a
                  rel='noreferrer'
                  target='_blank'
                  href='https://twitter.com/easyshareafrica'>
                  <img src={ twitter } alt='twitter logo' />
                </a>

                <a
                  rel='noreferrer'
                  target='_blank'
                  href='https://www.instagram.com/easyshareafrica/'>
                  <img src={ insta } alt='instagram logo' />
                </a>

                <a
                  rel='noreferrer'
                  target='_blank'
                  href='https://t.me/easyshareafricacommunity'>
                  <img src={ telegram } alt='telegram logo' />
                </a>

                <a
                  className='fb'
                  rel='noreferrer'
                  target='_blank'
                  href='https://www.facebook.com/easyshareafrica/'>
                  <img src={ facebook } alt='facebook logo' />
                </a>

              </div>

              <p> &copy; 2021. Easyshare</p>
            </section>
          </div>
        </div>
      </footer>
      <div className={ `toast ${ visible ? "visible" : "" }` }>
        <div className="content">
          <p>Thank you for subscribing</p>
        </div>
      </div>
    </>
  ) : null;
}

export default Footer;
