import React, { useEffect, useState } from "react";
import { UserService } from "../../Factory/User/User.service";
import { Formik } from 'formik';
import toast from 'react-hot-toast'
import * as Yup from 'yup';
import side from "../../../../assets/images/Polygon.png";
import up from "../../../../assets/images/Polygonup.png";


const initValues =
{
  currentPassword: "",
  newPassword: "",
  confirmPassword: ""
};

const validationSchema = Yup.object(
  {
    currentPassword: Yup.string().required( "Password is required" ),
    newPassword: Yup.string()
      .required( 'No password provided.' )
      .matches(
        /^.*(?=.{8,})((?=.*[!@£#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase,one lowercase, one number and one special case character"
      ),
    confirmPassword: Yup.string()
      .test( 'confirmPassword', 'Passwords must match', function ( value )
      {
        return this.parent.newPassword === value;
      } )
  }
);

function ChangePassword ( props )
{
  const [ err, setErr ] = useState( "" );
  const [ clicked, setClicked ] = useState( false );
  const [ show, setShow ] = useState( false );
  const [ f, setF ] = useState( null );
  const [ successMessage, setSuccesMessage ] = useState("");

  useEffect(()=>{
    let timerId;
      if(successMessage){
          timerId = setTimeout( ()=>setSuccesMessage(""),2000)
      }
      return () =>{
        clearTimeout(timerId);
      }
  },[successMessage])

  function handleClick ()
  {
    setClicked( ( prev ) => !prev );
  }

  const togglePassword = () => setShow( s => !s );



  return (
    <Formik
      initialValues={ initValues }
      initialErrors={ initValues }
      validationSchema={ validationSchema }
      onSubmit={ ( values, { resetForm } ) =>
      {
        setErr( "" );
        UserService.resetUserPassword( values ).then( p =>{
            resetForm();
            setSuccesMessage(p?.message || "password changed");
            handleClick();
            f?.reset();  
            toast.success(p?.message || "password changed",{
              duration: 9000,
            })
        }).catch( err => {
          if(Array.isArray(err)){
                setErr(err?.join(""))
          }else{
               setErr(err?.message || err);
          }    
        });
       
       
      } }
    >
      {
        ( {
          errors,
          touched,
          values,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit
        } ) => (

          <div className='dropdown_section'>
            <button
              className={ `dropdown ${ clicked && "active" }` }
              onClick={ handleClick }>
              <p>
                { successMessage ? successMessage : "Reset Password" }
              </p>
              <img src={ clicked ? up : side } className='icon' alt='caret' />
            </button>
            <div id='dropdown_content' className={ `${ clicked ? "show" : "hide" }` }>
              <form onSubmit={ e => 
              {
                e.preventDefault();
                setF( e.target );
                handleSubmit( values );
              } }
                autoComplete="off"
                className="form">

                { err ? <small className="form-error">{ err }</small> : null }
                <br />
                <br />
                <div className='form_group'>
                  <label htmlFor='currentPassword'>Current Password </label>
                  <input
                    type={ show ? 'text' : 'password' }
                    name='currentPassword'
                    value={ values.currentPassword }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                  />
                  <i
                    className={ `eye far ${ show ? "fa-eye-slash" : "fa-eye" }` }
                    onClick={ togglePassword }></i>
                  { touched.currentPassword && errors.currentPassword ? (
                    <small className="error">{ errors.currentPassword }</small>
                  ) : null }
                </div>
                <br /> <br />

                <div className='form_group'>
                  <label htmlFor='newPassword'>Password</label>
                  <input
                    type={ show ? 'text' : 'password' }
                    name='newPassword'
                    value={ values.newPassword }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                  />
                  <i
                    className={ `eye far ${ show ? "fa-eye-slash" : "fa-eye" }` }
                    onClick={ togglePassword }></i>
                  { touched.newPassword && errors.newPassword ? (
                    <small className="error">{ errors.newPassword }</small>
                  ) : null }
                </div>
                <br /> <br />
                <div className='form_group'>
                  <label htmlFor='confirmPassword'>Confirm Password</label>
                  <input
                    type={ show ? 'text' : 'password' }
                    name='confirmPassword'
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                  />
                  <i
                    className={ `eye far ${ show ? "fa-eye-slash" : "fa-eye" }` }
                    onClick={ togglePassword }></i>
                  { touched.confirmPassword && errors.confirmPassword ? (
                    <small className="error">{ errors.confirmPassword }</small>
                  ) : null }
                </div>
                <div className='form_group'>
                  <button disabled={ !isValid } type="submit" className='submit_button'>Submit</button>
                </div>
              </form>
            </div>
          </div>

        )
      }
    </Formik>
  );
}

ChangePassword.propTypes = {};

export default ChangePassword;
