import React, { useState } from 'react';
import data from "../../assets/currency-country.json";
import "./PhoneInput.scss";

function PhoneInput ( props ) 
{
  const [ show, setShow ] = useState( false );
  const [ selected, setSelected ] = useState( 0 );
  const [ focused, setFocus ] = useState( false );
  const
    {
      error,
      touched,
      value,
      handleChange,
      setFieldValue,
      handleBlur,
      setShort,
      handleInput
    } = props;


  const toggle = ( value ) => setShow( value );

  const change = ( e, i, short ) =>
  {
    e.stopPropagation();
    setSelected( i );
    toggle( false );
    setShort( short );
    setFieldValue( "country", short, false );
  };

  return (
    <div className='Phone-row'>
      <label htmlFor='phone'>
        Phone number { error && touched ? <small>({ error })</small> : null }
      </label>
      <div className={ ` ${ focused ? "Phone active" : "Phone" }` }>
        <div onClick={ () => toggle( !show ) } className='dropdwn'>
          <span className='img'>
            <img src={ data[ selected ].flag } alt={ data[ selected ].country } />
            <i className="fas fa-caret-down"></i>
          </span>

          <ul className={ `list ${ show ? "active" : "" }` }>
            { data.map( ( c, i ) =>
            {
              return (
                <li key={ c.flag }>
                  <button
                    type='button'
                    onClick={ ( e ) => change( e, i, c.code ) }>
                    <span className='img'>
                      <img src={ c.flag } alt={ c.country } />
                    </span>

                    <span>{ c.country }</span>
                  </button>
                </li>
              );
            } ) }
          </ul>
        </div>

        <input
          onChange={ handleChange }
          value={ value }
          onFocus={ () =>
          {
            toggle( false );
            setFocus( true );
          } }
          onBlur={ ( e ) =>
          {
            toggle( false );
            handleBlur( e );
            setFocus( false );
          } }
          onInput={ handleInput }
          type='tel'
          name='phone'
          id='phone'
        />
      </div>
    </div>
  );
}

export default PhoneInput;
