import React, { useReducer, createContext } from "react";
import countries from "../assets/currency-country.json";

const UIContext = createContext();



const initialState = {
  isFooterHidden: false,
  isSideBarOpened: false,
  receiveMode: 0,
  receiveCurrency: null,
  sendCurrency: null,
  banks: [],
  operators: [],
  countries: [],
  pickedAsset: {
    index: null,
    asset: null,
  },
  operatorOption: {
    country: "Nigeria",
    phone: "",
    operator: 999992,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "set-receiveMode":
      return { ...state, receiveMode: action.receiveMode };
    case "set-countries":
      return { ...state, countries: action.countries };

    case "set-currency":
      const { receiveCurrency, sendCurrency } = action;
      return { ...state, receiveCurrency, sendCurrency };

    case "set-banks":
      return { ...state, banks: action.banks };

    case "set-obanks":
      return { ...state, operators: action.operators };
    case "set-picked-asset":
      return { ...state, pickedAsset: action.pickedAsset };

    case "hide-footer":
      return { ...state, isFooterHidden: true };

    case "show-footer":
      return { ...state, isFooterHidden: false };
    case "toggle-sidebar":
      return { ...state, isSideBarOpened: !state.isSideBarOpened };
    case "clear":
      return initialState;

    default:
      return state;
  }
};

function UIProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const boundActions = {
    clearForm: () => dispatch({ type: "clear" }),
    setField: (field, value) => dispatch({ type: "set-field", field, value }),
    setTransactionType: (transactionType) =>
      dispatch({ type: "set-transactionType", transactionType }),
    setBanks: (banks) => dispatch({ type: "set-banks", banks }),
    setRM: (receiveMode) => dispatch({ type: "set-receiveMode", receiveMode }),
    setCountries: (countries) => dispatch({ type: "set-countries", countries }),
    setCurrency: ({ sendCurrency, receiveCurrency }) =>
      dispatch({ type: "set-currency", receiveCurrency, sendCurrency }),
    setObanks: (operators) => dispatch({ type: "set-obanks", operators }),
    hideFooter: () => dispatch({ type: "hide-footer" }),
    showFooter: () => dispatch({ type: "show-footer" }),
    toggleSidebar: () => dispatch({ type: "toggle-sidebar" }),
    setPickedAsset: (pickedAsset) =>
      dispatch({ type: "set-picked-asset", pickedAsset }),
  };

  return (
    <UIContext.Provider
      value={{
        UiState: state,
        UiActions: boundActions,
      }}>
      {children}
    </UIContext.Provider>
  );
}

function useUiState() {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error("useUIState must be used within a UIProvider");
  }
  return context;
}

export { UIProvider, UIContext, useUiState };
