import { useState, useCallback } from 'react';

function usePage ( pages ) 
{
    let isArrayOfSteps = Array.isArray(pages);
    let numOfPages = isArrayOfSteps ? pages.length : pages ;
    
    const [ page, setPage ] = useState( 0 );
    const [ verifiedPages, setVP ] = useState( new Array( numOfPages ).fill( false ) );

    const changePage = ( newPage, canJump ) =>
    {
         //check if newPage is a string and if an array was passed 
         if(typeof newPage === "string" && isArrayOfSteps )
         {
              //find the index of the string from the array 
               newPage =  pages.indexOf(newPage);
              
              //if it wasnt found, throw error
               if(newPage === -1){
                   throw Error(`invalid page ${ newPage } `)
               }
          }

        if ( newPage >= 0 && canJump )
        {
            setPage( newPage );
            const verified = [ ...verifiedPages ];
            if ( page === 0 )
            {
               verified[ 0 ] = true;
            }
            verified[ newPage ] = canJump;

            setVP( verified );
            return;
        }

    };

   const clearVp = useCallback(() => {
    setVP((prev_vp) => {
      return new Array(prev_vp.length).fill(false);
    });
  }, [setVP]);
    
    return { page, changePage, verifiedPages, clearVp };
}

export default usePage;
