import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import "yup-phone";
import { Formik, Form } from "formik";
import { baseUrl } from "../../utils";
import { Spinner, BackBtn } from "../index";
import PhoneInput from "../PhoneInput";

const BANKNAME = "PAYCOM (Opay)";

function SelectOperator ( {
  setPaymentMethod,
  setPhone,
  setAccountData,
  changePage,
  setField,
  setObanks,
  page,
  back,
  data,
  text
} )
{
  const [ , setShort ] = useState( "NG" );
  const [ operatorLoading /*setOL*/ ] = useState( false );
  const { accountDetails: account, phoneNumber } = data.transactionalState;
  const [ userLoading, setUL ] = useState( { loading: false, error: null } );

  const operatorSchema = Yup.object().shape( {
    country: Yup.string().required( "Country is required" ),
    operator: Yup.string().required( "Operator is required" ),
    phone: Yup.string()
      //.phone( short, true, "Invalid Phone number" )
      .min( 10, "At least 10 characters" )
      .required( "Phone number is required" )
      .matches(
        /^[0-9-+()\s]+$/,
        "Only numbers, +, - and space characters are allowed "
      ),
  } );

  const initialValues = data?.core?.operatorOption || {
    country: "NGN",
    phone: "",
    operator: 999992,
  };

  const { operators } = data.core;

  useEffect( () =>
  {
    // if ( !operators.length )
    // {
    //     const getOperators = async () =>
    //     {
    //         try
    //         {
    //             setOL( true );
    //             const resp = await fetch( `${ baseUrl }/utils/opay/banks` );

    //             if ( resp.ok )
    //             {
    //                 const json = await resp.json();
    //                 setOL( false );
    //                 const banks = json.data.map( ( { name, code, id } ) => ( {
    //                     name,
    //                     id,
    //                     code,
    //                 } ) );
    //                 setObanks( banks );
    //             }
    //         } catch ( error )
    //         {
    //             setOL( false );
    //             alert( "Unable to fectch banks. Please check your connection" );
    //         }
    //     };

    //     getOperators();
    // }

    setPaymentMethod( "OPAY" );
  }, [ operators, /* setObanks,*/ setPaymentMethod ] ); //

  const goNext = ( e ) =>
  {
    e.stopPropagation();
    changePage( page + 1, true );
  };

  const verifyUser = async ( phone ) =>
  {
    setUL( { loading: true, error: null } );
    try
    {
      const resp = await fetch(
        `${ baseUrl }/utils/opay/info/user?phoneNumber=${ phone }`
      );

      const json = await resp.json();
      if ( resp.ok && json.data )
      {
        const { firstName, lastName } = json.data;

        setUL( { loading: false, error: null } );
        setField( "accountName", `${ firstName } ${ lastName }` );
        return;
      } else
      {
        setUL( { loading: false, error: "User not found" } );
        setField( "accountName", `` );
      }
    } catch ( error )
    {
      setUL( { loading: false, error: error.toString() } );
      setField( "accountName", `` );
    }
  };

  const submitForm = async ( values ) =>
  {
    verifyUser( values.phone );
    if ( values.operator )
    {
      setAccountData( {
        accountName: account.accountName,
        bankCode: values.operator,
        bankName: values.bankName || BANKNAME,
        //bankName: operators.find( b => b.code === values.operator ).name,
        country: values.code,
        code: values.isoCode,
        accountNumber: values.phone.substring( values.phone.length - 10 ),
      } );
    }
  };

  const changeHandler = ( e, formikHandler ) =>
  {
    formikHandler( e );
    const { name, value } = e.target;
    console.log(value);

    if ( name === "operator" )
    {
      //  const bankName = operators.find( b => b.code === value ).name;

      setField( "bankCode", value, false );
      setField( "bankName", BANKNAME );
    } else
    {
      //setField( name, value );
    }
  };

  useEffect( () =>
  {
    setField( "accountNumberType", "PHONE" );
    setField( "bankName", BANKNAME, false );
    setField( "bankCode", 999992, false );
  }, [ setField ] );

  return (
    <div>
      <p className='formMessage'>
        {text}
        <br /> below
      </p>

      <Formik
        initialValues={ initialValues }
        validationSchema={ operatorSchema }
        onSubmit={ submitForm }>
        { ( { errors, touched, values, handleChange, handleBlur } ) => (
          <Form autoComplete='off' className='mode'>
            <BackBtn to={ back } />
            <PhoneInput
              setShort={ setShort }
              setFieldValue={ setField }
              error={ errors.phone }
              touched={ touched.phone }
              value={ values.phone || phoneNumber }
              handleBlur={ handleBlur }
              handleChange={ ( e ) => changeHandler( e, handleChange ) }
              handleInput={ ( e ) =>
              {
                setPhone( e.target.value );
                if ( e.target.value.length > 10 )
                {
                  setField(
                    "accountNumber",
                    e.target.value.substring( e.target.value.length - 10 )
                  );

                  if ( e.target.value !== account.phone )
                  {
                    setField( "accountName", "" );
                  }
                  submitForm( { ...values, phone: e.target.value } );
                }
              } }
            />
            <div className='form-row'>
              <label htmlFor='operator'>
                Operator{ " " }
                { errors.operator && touched.operator ? (
                  <small> ({errors.operator }) </small>
                ) : null }
              </label>
              <select
                name='operator'
                id='operator'
                value={ account.bankCode }
                onChange={ ( e ) => changeHandler( e, handleChange ) }
                onBlur={ handleBlur }
                disabled={ true }>
                {/* { operators.map( ( d, i ) => (
                                    <option
                                        data-name={ d.name }
                                        key={ d.code }
                                        value={ d.code }>
                                        {d.name }
                                    </option>
                                ) ) } */}

                <option data-name={ BANKNAME } value={ 999992 }>
                  { BANKNAME }
                </option>
              </select>
              { operatorLoading ? (
                <div>
                  <span>
                    <Spinner />
                  </span>
                  &nbsp;&nbsp;&nbsp; <span> Loading operators </span>
                </div>
              ) : null }
            </div>

            <div className='form-row'>
              { userLoading.error ? <small>{ userLoading.error }</small> : null }

              { userLoading.loading && !userLoading.error ? (
                <div>
                  <span>
                    <Spinner />
                  </span>
                  &nbsp;&nbsp;&nbsp; <span> Verifying</span>{ " " }
                </div>
              ) : null }

              { account?.accountName && !userLoading.loading ? (
                <p className='user'>{ account?.accountName }</p>
              ) : null }
            </div>

            <button
              style={ { marginTop: 20 } }
              disabled={ !account.accountName }
              className='submit'
              type='button'
              onClick={ goNext }>
              Continue
            </button>
          </Form>
        ) }
      </Formik>
    </div>
  );
}

export default SelectOperator;
