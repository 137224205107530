import React from 'react';

function BackBtn ( { to } ) 
{
    return (
        <button onClick={ to } className="back">
            <i className="fas fa-angle-left"></i>
        </button>
    );
}

export default BackBtn;
