export const baseUrl = process.env.REACT_APP_BACKEND_URL;

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      // console.log(response)
      return Promise.reject(error);
    }
    return data;
  });
}

export function makeReq(path, configs = {}) {
  return fetch(`${baseUrl}/${path}`, {
    accept: "*/*",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    ...configs,
  }).then(handleResponse);
}

export function isObject(obj) {
  return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
}

export function isEmpty(empty) {
  //if it's not an object we will consider it empty
  if (!isObject(empty)) return true;

  return Object.keys(empty).length === 0 && empty.constructor === Object;
}

export const format = (value) => Intl.NumberFormat().format(value);

export function checkedJsonStorage(itemName, isSession = false) {
  let myItem = null;
  let storageType = isSession ? sessionStorage : localStorage;
  try {
    myItem = JSON.parse(storageType.getItem(itemName));
  } catch (err) {
    // console.log(err);
  }
  return myItem;
}

export function sortByKeyAsc(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function listToMatrix(list, elementsPerSubArray) {
  var matrix = [],
    i,
    k;

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }

    matrix[k].push(list[i]);
  }

  return matrix;
}

export function buildQueryString(queryObj) {
  const query = {};
  let queryString = ``;

  for (let key in queryObj) {
    if (queryObj[key]) {
      query[key] = queryObj[key];
      queryString += `${key}=${encodeURIComponent(queryObj[key])}&`;
    }
  }
  //remove the last &
  queryString = queryString.slice(0, queryString.lastIndexOf("&"));
  return queryString;
}


export const NetworkMap = {
  TRX: "TRON",
  ETH: "ETH",
  BNB: "BSC"
}