const notAvail = ( value ) => 
{
    return value ? value : "N/A";
};

const dateFormatter = ( date ) =>
{
    if ( !date ) return "N/A";

    const formatter = new Intl.DateTimeFormat( 'en-US',
        {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        } );

    const d = new Date( date );

    return formatter.format( d );
};

export 
{
    dateFormatter,
    notAvail
};
