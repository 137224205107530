import React from "react";
import Table from "../Table";
import "./ReferralTable.scss";
import { GridBox, GridContainer  } from "../../../../components";
import { useUserState } from "../../Factory/User/User.context";
import { useUserAction } from "../../Factory";
import { dateFormatter, notAvail } from "./tableUtils";
import RefreshButton from "../RefreshButton/RefreshButton";

const columns = [
  {
    name: "name",
    label: "Name",
    style: {
      color: "rgba(48, 48, 48, 0.5)",
    },
    formatter: value => notAvail( value )
  },
  {
    name: "email",
    label: "Email",
    formatter: value => notAvail( value )
  },
  {
    name: "total_transaction_count",
    label: "Transactions",
    style: {
      fontSize: 18,
      fontWeight: 600,
    },
  },
  {
    name: "joined",
    label: "Joined",
    formatter: date => dateFormatter( date )
  },
  {
    name: "last_active",
    label: "Last Active",
    formatter: date => dateFormatter( date )
  },
];


const initState =
{
  content: [],
  totalPages: 0,
  totalElements: 0
};

const returnState = ( state ) =>
{
  if ( state.totalPages ) return state;
  return initState;
};

// const isThisMonth = data =>
// {
//    const date = new Date();
//    const thisMonth = date.getMonth();
//   const thisDate= date.getDate();

//   return data.filter( d => 
//     {
//         let isValid = true;

//     return new Date(d.)
//     } )

// }

function ReferralTable ( props ) 
{
  const { referrals } = useUserState();
  const { content, totalPages, number, totalElements } = returnState( referrals );
  const userAction = useUserAction();

  const getPage = ( page = 1 ) =>
  {
    userAction.getUserReferrals( { page } )
      .then( p => p )
      .catch( err => console.log( err ) );
  };

  // const isEmpty = (refs) => {
  //   if (Object.keys(refs).length === 0) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }


  return (
    <div className='affiliate__table'>
      <GridContainer>
        <GridBox title='This month'>
          <div className='info_count'>
            {/* Message ogheneovo */ }
            <h2>0</h2>
            <p>New Referrals</p>
          </div>
        </GridBox>
        <GridBox title='Total'>
          <div className='info_count'>
            <h2>{ totalElements }</h2>
            <p>Referrals</p>
          </div>
        </GridBox>
        <GridBox className='last_box' title='TOP PERFORMING REFERRAL'>
          <div className='last_wrapper'>
            <div className='flexed user_info'>
              <div className='top_affiliate'>
                <img
                  src='https://source.unsplash.com/BKxipJKe3G0/800x799'
                  alt='top_affiliate_gravater'
                />
                <div>
                  <p className='name'>Tobi, Oni </p>
                  <p className='location'>Abuja, Nigeria</p>
                </div>
              </div>
              <div className='transaction'>
                <h5>1,011 </h5>
                <p>Transactions</p>
              </div>
            </div>

            <div className='flexed bottom_activity'>
              <div className='activity'>
                <p>Joined</p>
                <p>May 2, 2019</p>
              </div>
              <div className='activity'>
                <p>Last active</p>
                <p>June 8, 2019</p>
              </div>
            </div>
          </div>
        </GridBox>
      </GridContainer>
      <div className='table_region'>

        <RefreshButton text="Refresh referrals"
          handler={ () => getPage( 1 ) } />
        <Table
          columns={ columns }
          data={ /*data*/content }
          title='Referrals'
          getPage={ getPage }
          totalPages={ totalPages }
          currentPage={ number } />
      </div>
    </div>
  );
}

ReferralTable.propTypes = {};

export default ReferralTable;
