import React from "react";
import { useUiState } from "../../../../_helpers";
//import PropTypes from 'prop-types'
import "./Header.scss";

function Header(props) {
    const { UiActions } = useUiState();
  return (
    <div className='afiliate_header'>
      <div className='mobile_toggler'>
        <span className='toggler' onClick={UiActions.toggleSidebar}>
          <i className='fa fa-bars'></i>
        </span>
      </div>
      <div className="header_end">
        <div className='search'>
          <input type='text' placeholder='Search' />
          <i className='fa fa-search'></i>
        </div>
         <div className="notification_icon">
             <span>
                 <i className="fa fa-bell"></i>
                 {/* <small>1</small> */}
             </span>
         </div>
      </div>
    </div>
  );
}

Header.propTypes = {};

export default Header;
