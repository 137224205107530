import React from 'react';
import "./Gravatar.scss";

function Gravatar ( { name, profileImage, loading, onChange } )
{

  async function handleDrop ( e )
  {
    const file = e.target.files[ 0 ];
    const reader = new FileReader();
    reader.onloadend = () =>
    {
      // use a regex to remove data url part
      const base64String = reader.result;
      onChange( base64String );
    };
    reader.readAsDataURL( file );

  }


  return (
    <div className='gravatar'>
      <div className='gravatar_image'>
        <label htmlFor="fileElem" className='btnUpload'>
          { profileImage ? "change" : "upload" }
        </label>
        <input
          type='file'
          className='visually-hidden'
          id='fileElem'
          onChange={ handleDrop }
        />

        <img
          src={
            profileImage ||
            "https://cdn.myanimelist.net/images/anime/1429/95946.jpg"
          }
          alt={ `${ name } gravatar` }
        />
      </div>

      <p className='gravatar_info'> { name } </p>
    </div>
  );
}

Gravatar.propTypes = {

};

Gravatar.defaultProps = {
  onChange: () => { }
};

export default Gravatar;
