import React from "react";
import { Switch, Route } from "react-router-dom";
import Layout from "./Layout";
import Login from "./Login/Login";
import Signup from "./SignUp/SignUp";
import Home from "./Home/Home";
import { PasswordRecovery, SetNewPassword } from "./PasswordRecovery";
import { ScrollToTop } from "../../components";
import { PrivateRoute, PublicRoute } from "./Components";
import { UserProvider } from "./Factory";
import NotFound from "../NotFound";
import Verify from "./Components/Verify/Verify";

export default function Affiliate ( { match } )
{
  return (
    <UserProvider>
      <Switch>
        <PrivateRoute path={`${match.path}/dashboard`} component={Layout} />
        <Route exact path={`${match.path}`} component={Home} />
        <PublicRoute exact path={`${match.path}/login`} component={Login} />
        <PublicRoute exact path={`${match.path}/signup`} component={Signup} />
        <Route exact path={`${match.path}/auth/confirm`} component={Verify} />
        <PublicRoute
          exact
          path={`${match.path}/forgot-password`}
          component={PasswordRecovery}
        />
        <PublicRoute
          exact
          path={`${match.path}/create-new-password`}
          component={SetNewPassword}
        />
        <Route exact path='*'>
          <ScrollToTop>
            <NotFound url='/affiliate/dashboard' />
          </ScrollToTop>
        </Route>
      </Switch>
    </UserProvider>
  );
}

Affiliate.propTypes = {};
