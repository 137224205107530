import React from 'react';
import "./RefreshButton.scss";

function RefreshButton ( { text, handler } ) 
{

    return (
        <button onClick={ handler } className="refresh">
            { text }
        </button>
    );
}

export default RefreshButton;
