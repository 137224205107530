import React, { useContext, useState, useEffect } from "react";
import { withProvider } from "../../hocs";
import { CoinContext, CoinProvider } from "../../_helpers";
import NetworkList from "./NetworkList";
import { BackBtn } from "../index";
import "./Form.scss";
import { format } from "../../utils";

function SelectCrypto({
  changePage,
  page,
  setFormData,
  setTokenDto,
  setPickedAsset,
  data,
}) {
  const { tokenDto, accountDetails, payWithCrypto } = data.transactionalState;
  const { pickedAsset } = data.core;
  const { coins, loading } = useContext(CoinContext);
  const [asset, setAsset] = useState(pickedAsset?.asset);

  const pickedToken = () => (asset?.tokenOption ? !!tokenDto?.tokenName : true);

  useEffect(() => {
    if (!asset?.tokenOption) {
      setTokenDto(null);
    }
  }, [asset?.tokenOption, setTokenDto]);




  function handleSelectChange(e) {
    const value = e.target.value;
    const picked = coins[value];

    if (picked) {  
      setAsset(picked);
      setPickedAsset({ asset: picked, index: value });
      setFormData({ payWithCrypto: picked?.value });
    }
  }

  const firstname = accountDetails?.accountName?.split(" ")[0];

  const goNext = (e) => {
    e.stopPropagation();
    changePage(page + 1, true);
  };

  const goBack = (e) => {
    e.stopPropagation();
    changePage(page - 1, true);
  };

  return (
    <div className='select_asset'>
      <BackBtn to={goBack} />
      <p className='formMessage'>
        to <span className='emph bold'>{firstname}</span>
        <br />
        {accountDetails.bankName}
      </p>
      <div style={{ margin: "20px 0" }}></div>
      <div className='form-row'>
        <label htmlFor='asset'>Which crypto would you like to send?</label>
        <select
          name='asset'
          className='big_select'
          onChange={handleSelectChange}
          disabled={loading}
          value={pickedAsset.index || ""}>
          <option defaultValue> select crypto </option>
          {coins &&
            coins?.map((value, index) => (
              <option key={value?.crypto} value={index}>
                {value?.cryptoDisplayName}
              </option>
            ))}
        </select>
      </div>

      {asset?.tokenOption ? (
        <div className='network_region'>
          <NetworkList
            data={asset?.tokenOption}
            selected={tokenDto}
            onChange={setTokenDto}
          />
        </div>
      ) : !!asset ? (
        <div className='limit_info'>
          <p className='select_icon'>
            <i className='fa fa-info-circle'></i>
          </p>
          <p>
            {" "}
            Minimum amount of {asset?.label} that can be <br /> sent
            is <span className='lightened'>{asset?.minLimit}</span>
          </p>
        </div>
      ) : (
        <div className='limit_info'>
          <p className='select_icon'>
            <i className='fa fa-info-circle'></i>
          </p>
          <p>
            Kindly note that there is a{" "}
            <span className='lightened'>minimum amount of each crypto</span> you
            can send.
          </p>
        </div>
      )}

      <button
        className='submit'
        onClick={goNext}
        disabled={!pickedToken() || !payWithCrypto}>
        Continue
      </button>
    </div>
  );
}

SelectCrypto.propTypes = {};

export default withProvider(CoinProvider)(SelectCrypto);
